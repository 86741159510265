enum DateEnum {
    'Month to Date',
    'Last Month',
    'Last 3 Months',
    'Last 6 Months',
    'Last 9 Months',
    'Last Year',
    'Year to Date',
}

export type DateEnumUnion = keyof typeof DateEnum;

export default DateEnum;

// const dateRange = DateEnum['Last Year']; //5
