import { PieLayer } from '@nivo/pie';
import { LabelNameDictionaryType } from '../models/PageModels/_Interface/IPageModelsInterface';
import { PieChartDatum } from '../models/PieChartDatum';
import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import PieChartSavingsModel from '../models/PieChartModels/PieChartSavingsModel';
import { EMPTY_VALUE_PLACEHOLDER_4_SPACES } from '../utils/EmptyValuePlaceholderUtil';
import { isNullOrUndefined } from '../utils/NullOrUndefined';
import { PieChart } from './PieChart';
import './PieChart.css';

interface PieChartProps<T extends PieChartSavingsModel | PieChartAssetModel> {
    pieChartData: T | null;
    pieChartLabelNameDictionary: Partial<LabelNameDictionaryType<T>>;
    pieChartCenteredMetricValue: string;
    pieChartSavingsColor: string;
    pieChartReducedColor: string;
    pieChartTitle: string;
    pieChartSize?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    pieChartOrientation: 'horizontal' | 'vertical';
    pieChartDate?: string;
    pieChartContainerHasLegend: boolean;
}

type PieCenteredMetricType = (centeredProps: string) => PieLayer<PieChartDatum>;

const PieCenteredMetric: PieCenteredMetricType = (centeredProps) =>
    function PieCenteredMetricComp(layerProps) {
        const { centerX, centerY } = layerProps;

        return (
            <text
                x={centerX}
                y={centerY}
                textAnchor="middle"
                dominantBaseline="central"
                style={{
                    fontSize: '1.5rem',
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                    color: '#11263C',
                }}>
                {centeredProps}
            </text>
        );
    };

// const generateValuesForPieCenteredMetric = (pieChartData: PieChartSavingsModel | PieChartAssetModel | null) => {
//     if (isNullOrUndefinedOrEmptyString(pieChartData)) {
//         return EMPTY_VALUE_PLACEHOLDER_2_SPACES;
//     }

//     if (pieChartData instanceof PieChartSavingsModel) {
//         return Math.ceil((pieChartData.savings / (pieChartData.savings + pieChartData.reducedCost)) * 100);
//     }

//     return Math.ceil((pieChartData.meetsCriteria / pieChartData.totalAssets) * 100);
// };

export const PieChartContainer = <T extends PieChartSavingsModel | PieChartAssetModel>({
    pieChartData,
    pieChartTitle,
    pieChartSize,
    pieChartDate,
    pieChartOrientation,
    pieChartContainerHasLegend,

    pieChartSavingsColor,
    pieChartReducedColor,
    pieChartLabelNameDictionary,
    pieChartCenteredMetricValue,
}: PieChartProps<T>) => {
    return pieChartData instanceof PieChartSavingsModel && pieChartContainerHasLegend ? (
        pieChartOrientation === 'horizontal' ? (
            // Horizontal pie chart with legend
            <div id="pie-chart-container" className="flex flex-col items-center h-[250px] w-[100%]">
                <div className="flex justify-center">{pieChartTitle}</div>
                <div className="flex justify-center text-3xl text-[#000000]">{pieChartDate}</div>
                <div className="text-[#0091C5] font-semibold text-3xl border-b-[#DBE6F5] border-b border-solid w-[233px] pb-[5px] flex justify-center">
                    {`$${(pieChartData?.savings ?? EMPTY_VALUE_PLACEHOLDER_4_SPACES).toLocaleString('en-US')}`}
                </div>
                <div className="flex w-[100%] justify-center">
                    <div className="h-[150px] w-[50%] -mr-10 my-1">
                        <PieChart<T>
                            pieChartLabelNameDictionary={pieChartLabelNameDictionary}
                            pieChartData={pieChartData}
                            pieChartSize={pieChartSize}
                            pieToolTipFormatting={'$'}
                            PieCenteredMetric={PieCenteredMetric(`${pieChartCenteredMetricValue}%`)}
                            pieChartSavingsColor={pieChartSavingsColor}
                            pieChartReducedColor={pieChartReducedColor}
                        />
                    </div>
                    <div className="flex w-[50%] items-center justify-center">
                        <div className="flex flex-col">
                            <p className="pie-chart-label-text"> </p>
                            <p className="pie-chart-value-text">{`$${(isNullOrUndefined(pieChartData)
                                ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                                : pieChartData.savings + pieChartData.reducedCost
                            ).toLocaleString('en-US')}`}</p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-[10px]">
                    <div className="flex gap-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <circle cx="7" cy="7" r="7" fill="#0091c5" />
                        </svg>
                        <div className="flex flex-col">
                            <p className="pie-chart-label-text">Savings</p>
                            <p className="pie-chart-value-text">{`$${(isNullOrUndefined(pieChartData)
                                ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                                : pieChartData?.savings
                            ).toLocaleString('en-US')}`}</p>
                        </div>
                    </div>
                    <div className="flex gap-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <circle cx="7" cy="7" r="7" fill="#DBE6F5" />
                        </svg>
                        <div className="flex flex-col">
                            <p className="pie-chart-label-text">Reduced Cost</p>
                            <p className="pie-chart-value-text">{`$${(isNullOrUndefined(pieChartData)
                                ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                                : pieChartData.reducedCost
                            ).toLocaleString('en-US')}`}</p>
                        </div>
                    </div>
                </div>
            </div>
        ) : (
            // Vertical pie chart with legend
            <div id="pie-chart-container" className="flex flex-col items-center h-[250px] w-[100%]">
                <div className="flex justify-center">{pieChartTitle}</div>
                <div className="flex justify-center text-sm leading-[22px] text-[#000000] opacity-[0.5]">
                    {pieChartDate}
                </div>
                <div className="text-[#0091C5] font-semibold text-3xl border-b-[#DBE6F5] border-b border-solid w-[233px] pb-[5px] flex justify-center">
                    {`$${(isNullOrUndefined(pieChartData)
                        ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                        : pieChartData.savings
                    ).toLocaleString('en-US')}`}
                </div>
                <div className="flex flex-col pt-10 w-[100%] justify-center items-center">
                    <div className="h-[150px] w-[100%]">
                        <PieChart
                            pieChartData={pieChartData}
                            pieChartSize={pieChartSize}
                            pieToolTipFormatting={'$'}
                            PieCenteredMetric={PieCenteredMetric(`${pieChartCenteredMetricValue}%`)}
                            pieChartSavingsColor={pieChartSavingsColor}
                            pieChartReducedColor={pieChartReducedColor}
                            pieChartLabelNameDictionary={pieChartLabelNameDictionary}
                        />
                    </div>
                    <div className="flex w-[50%] pt-10 justify-center">
                        <div className="flex flex-col justify-center">
                            <p className="pie-chart-label-text">Original Cost</p>
                            <p className="pie-chart-value-text">{`$${(isNullOrUndefined(pieChartData)
                                ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                                : pieChartData.savings + pieChartData.reducedCost
                            ).toLocaleString('en-US')}`}</p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-[10px] pt-10">
                    <div className="flex gap-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <circle cx="7" cy="7" r="7" fill="#0091c5" />
                        </svg>
                        <div className="flex flex-col">
                            <p className="pie-chart-label-text">Savings</p>
                            <p className="pie-chart-value-text">{`$${(isNullOrUndefined(pieChartData)
                                ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                                : pieChartData.savings
                            ).toLocaleString('en-US')}`}</p>
                        </div>
                    </div>
                    <div className="flex gap-[5px]">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                            <circle cx="7" cy="7" r="7" fill="#DBE6F5" />
                        </svg>
                        <div className="flex flex-col">
                            <p className="pie-chart-label-text">Reduced Cost</p>
                            <p className="pie-chart-value-text">{`$${(isNullOrUndefined(pieChartData)
                                ? EMPTY_VALUE_PLACEHOLDER_4_SPACES
                                : pieChartData.reducedCost
                            ).toLocaleString('en-US')}`}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    ) : (
        // PieChartContainer without legend
        <div id="pie-chart-container" className="flex flex-col items-center w-[100%]">
            <div className="flex justify-center">{pieChartTitle}</div>
            <div className="flex w-[100%] justify-center">
                <div className="h-[20rem] w-[100%]">
                    <PieChart
                        pieChartData={pieChartData}
                        pieChartSize={pieChartSize}
                        pieToolTipFormatting={''}
                        PieCenteredMetric={PieCenteredMetric(`${pieChartCenteredMetricValue}%`)}
                        pieChartSavingsColor={pieChartSavingsColor}
                        pieChartReducedColor={pieChartReducedColor}
                        pieChartLabelNameDictionary={pieChartLabelNameDictionary}
                    />
                </div>
            </div>
        </div>
    );
};
