import React, { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import { LabelNameDictionaryType } from '../models/PageModels/_Interface/IPageModelsInterface';
import { isNotNullNorUndefined } from '../utils/NullOrUndefined';

interface SelectableTableProps<T> {
    columns: LabelNameDictionaryType<T> | undefined;
    data: T[] | undefined;
    selectedTableData: T | null;
    setSelectedTableData: React.Dispatch<React.SetStateAction<T | null>>;
    setCurrentPage?: React.Dispatch<React.SetStateAction<number>>;
    toggleTable: boolean;
    currentPage?: number;
    totalPages?: number;
    totalItems?: number;
    onPageChange?: (pageNumber: number) => void;
    pageSize?: number;
}

export const SelectableRowTable = <T,>({
    columns,
    data,
    selectedTableData,
    setSelectedTableData,
    toggleTable,
}: // eslint-disable-next-line @typescript-eslint/no-empty-function
// eslint-disable-next-line @typescript-eslint/no-empty-function
SelectableTableProps<T>) => {
    //-----------STATE---------------
    const handleRowClick = (rowData: T) => {
        setSelectedTableData(rowData);
    };

    const [containerClass, setContainerClass] = useState<string>('sticky top-0 z-[1] overflow-auto h-full');

    useEffect(() => {
        if (toggleTable === true) {
            // if table is open
            setContainerClass('overflow-auto h-[700px]');
        } else {
            // if table is closed
            setContainerClass('overflow-auto h-full pb-[25px]');
        }
    }, [toggleTable]);

    return (
        <div className={containerClass}>
            <Table className="mb-[25px]">
                <thead>
                    <tr>
                        {isNotNullNorUndefined(columns) ? (
                            Object.entries(columns).map(([key, label]) => (
                                <th className="sticky top-0 z-[1] font-semibold text-base" key={key}>
                                    {label + ''}
                                </th>
                            ))
                        ) : (
                            <th className="sticky top-0 z-[1] font-semibold text-base">No Data</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {data === undefined || data === null || (Array.isArray(data) && data.length <= 0) ? (
                        <div>No results</div>
                    ) : (
                        data.map((row, index) => (
                            <tr
                                style={selectedTableData === row ? { backgroundColor: '#e5eff5ff' } : {}}
                                onClick={() => handleRowClick(row)}
                                key={index}>
                                {isNotNullNorUndefined(columns) ? (
                                    Object.keys(columns).map((key) => (
                                        <td key={key}>{row[key as keyof typeof row] + ''}</td>
                                    ))
                                ) : (
                                    <td>No Data</td>
                                )}
                            </tr>
                        ))
                    )}
                </tbody>
                {/* <PaginationComponent
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                /> */}
            </Table>
        </div>
    );
};
