import { NavLink } from 'react-router-dom';
import { ReactComponent as AccountIcon } from '../images/SideNavIcons/Account.svg';
import { ReactComponent as CleanupIcon } from '../images/SideNavIcons/Cleanup.svg';
import { ReactComponent as ComputeIcon } from '../images/SideNavIcons/Compute.svg';
import { ReactComponent as DashboardIcon } from '../images/SideNavIcons/Dashboard.svg';
import { ReactComponent as DatabaseIcon } from '../images/SideNavIcons/Database.svg';
import { ReactComponent as HelpIcon } from '../images/SideNavIcons/Help.svg';
import { ReactComponent as NotificationsIcon } from '../images/SideNavIcons/Notifications.svg';
import { ReactComponent as ReservationsIcon } from '../images/SideNavIcons/Reservations.svg';
import { ReactComponent as SettingsIcon } from '../images/SideNavIcons/Setting.svg';
import { ReactComponent as StorageIcon } from '../images/SideNavIcons/Storage.svg';
import { ReactComponent as Logo } from '../images/logo.svg';
import './NavBar.css';

export const NavBar = () => {
    return (
        <div id="nav-bar-container" className="w-[240px] bg-[#f1f2f7] min-w-fit text-[#11263c]">
            <div className="bg-[#f1f2f7] sticky z-[1] top-0 h-[64px] border-b-[#90949a] border-b border-solid">
                <div id="ccc-logo" className="flex justify-center items-baseline h-[64px] text-center ">
                    <Logo className="mx-[5px]" />
                    <p className="text-center text-[#005b99] text-me font-bold py-[25px] leading-[200%]">
                        Cloud Cost Control
                    </p>
                </div>
            </div>
            <div id="nav-bar-links" className="text-left ml-5 mr-5 my-[50px]">
                <ul>
                    <li className="text-[#11263c] font-semibold pl-[25px]">MENU</li>
                    <NavLink to="/dashboard">
                        <li>
                            <DashboardIcon className="navbar-icon" />
                            Main Dashboard
                        </li>
                    </NavLink>
                    <NavLink to="/compute">
                        <li>
                            <ComputeIcon className="navbar-icon" />
                            Compute
                        </li>
                    </NavLink>
                    <NavLink to="/databases">
                        <li>
                            <DatabaseIcon className="navbar-icon" />
                            Databases
                        </li>
                    </NavLink>
                    <NavLink to="/storage">
                        <li>
                            <StorageIcon className="navbar-icon" />
                            Storage
                        </li>
                    </NavLink>
                    <NavLink to="/cleanup">
                        <li>
                            <CleanupIcon className="navbar-icon" />
                            Cleanup
                        </li>
                    </NavLink>
                    <NavLink to="/recommendations">
                        <li>
                            <NotificationsIcon className="navbar-icon" />
                            Recommendations
                        </li>
                    </NavLink>
                    {/* <NavLink to="/licenses">
                        <li>
                            <LicensesIcon className="navbar-icon" />
                            Licenses
                        </li>
                    </NavLink> */}
                    <NavLink to="/reservations">
                        <li>
                            <ReservationsIcon className="navbar-icon" />
                            Reservations
                        </li>
                    </NavLink>
                </ul>
                <ul>
                    <li className="text-[#11263c] font-semibold pl-[25px]">OTHERS</li>
                    <NavLink to="/settings">
                        <li>
                            <SettingsIcon className="navbar-icon" />
                            Settings
                        </li>
                    </NavLink>
                    <NavLink to="/account">
                        <li>
                            <AccountIcon className="navbar-icon" />
                            Account
                        </li>
                    </NavLink>
                    <NavLink to="/help">
                        <li>
                            <HelpIcon className="navbar-icon" />
                            Help
                        </li>
                    </NavLink>
                </ul>
            </div>
        </div>
    );
};
