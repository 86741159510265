import { RecommendationsService } from '../../utils/apiServices';
import { LabelNameDictionaryType } from './_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<RecommendationsModel> = {
    notification: 'Notification',
    asset: 'Asset',
    currentCost: 'Current Cost',
    proposedCost: 'Proposed Cost',
};

class RecommendationsModel {
    notification: string;
    asset: string;
    currentCost: string;
    proposedCost: string;

    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = RecommendationsService.GetRecommendations;

    constructor(obj: RecommendationsModel) {
        this.notification = obj.notification;
        this.asset = obj.asset;
        this.currentCost = obj.currentCost;
        this.proposedCost = obj.proposedCost;
    }
}
export default RecommendationsModel;
