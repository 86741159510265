import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import './Dropdown.css';
import { SignInButton } from './SignInButton';
import { SignOutButton } from './SignOutButton';

interface AccountDropdownProps {
    isAuthenticated: boolean;
}

export const AccountDropdown = ({ isAuthenticated }: AccountDropdownProps) => {
    // State
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [userName] = useState<string>('Utility Trailer'); // State to track entered username

    console.log(isAuthenticated);
    return (
        <div>
            {isAuthenticated ? (
                <Dropdown
                    className="flex justify-end items-center w-[100%]"
                    isOpen={dropdownOpen}
                    toggle={() => setDropdownOpen((dropdownOpen) => !dropdownOpen)}>
                    <DropdownToggle caret className="dropdown-toggle-account account-text">
                        {isAuthenticated ? userName : <SignInButton />}
                    </DropdownToggle>
                    {isAuthenticated ? (
                        <DropdownMenu className="dropdown-menu-account date-text">
                            <div>
                                <DropdownItem className="dropdown-item-account w-full" tag={NavLink} to="/account">
                                    Account Settings
                                </DropdownItem>
                                <SignOutButton />
                            </div>
                        </DropdownMenu>
                    ) : null}
                </Dropdown>
            ) : (
                <SignInButton />
            )}
        </div>
    );
};
