import { useMsal } from '@azure/msal-react';
import { Button } from 'reactstrap';
import { loginApiRequest } from '../utils/AuthConfig';
import './Dropdown.css';

export const SignInButton = () => {
    const { instance } = useMsal();

    return (
        <div>
            <Button
                className="account-btn dropdown-toggle-account account-text"
                onClick={() => instance.loginRedirect(loginApiRequest)}
                color="inherit">
                Login
            </Button>
        </div>
    );
};
