import { AxiosResponse } from 'axios';
import { ComputeService } from '../../../utils/apiServices';
import TableDataRequest from '../../RequestModels/TableDataRequest';
import TableDataResponse from '../../ResponseModels/TableDataResponse';
import { LabelNameDictionaryType } from '../_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<ComputeContainersModel> = {
    asset: 'Asset',
    usage: 'Usage',
    originalCost: 'Original Cost',
    reducedCost: 'Reduced Cost',
    saved: 'Saved',
    cleanup: 'Cleanup',
};

class ComputeContainersModel {
    asset: string;
    usage: string;
    originalCost: string;
    reducedCost: string;
    saved: string;
    cleanup: string;

    static labelNameDictionary: LabelNameDictionaryType<ComputeContainersModel> = labelNameDictionary;

    static endpointMethod: (
        request: TableDataRequest
    ) => Promise<AxiosResponse<TableDataResponse<ComputeContainersModel>, unknown>> = ComputeService.GetContainers;

    constructor(obj: ComputeContainersModel) {
        this.asset = obj.asset;
        this.usage = obj.usage;
        this.originalCost = obj.originalCost;
        this.reducedCost = obj.reducedCost;
        this.saved = obj.saved;
        this.cleanup = obj.cleanup;
    }
}

export default ComputeContainersModel;
