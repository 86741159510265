import PieChartSavingsModel from '../models/PieChartModels/PieChartSavingsModel';
import { PieChartContainer } from './PieChartContainer';

// const pieChartData2 = [
//     new PieChartDatum('savings', 'Savings', 55000, 'hsla(196, 100%, 39%, 1)'),
//     new PieChartDatum('reducedCost', 'Reduced Cost', 45000, 'hsla(215, 57%, 91%, 1)'),
// ];

const currentMonth = new Date().toLocaleString('en-us', { month: 'long', year: 'numeric' });

export const MonthlySavings = () => {
    return (
        <div className="w-3/12 border-l-[#90949A] border-solid border-l">
            <PieChartContainer<PieChartSavingsModel>
                pieChartTitle={"Current Month's Savings"}
                pieChartDate={currentMonth}
                pieChartData={new PieChartSavingsModel({ savings: 343, reducedCost: 45454 })}
                // pieChartSize={{}}
                pieChartOrientation={'vertical'}
                pieChartContainerHasLegend={true}
                pieChartSavingsColor="hsla(196, 100%, 39%, 1)"
                pieChartReducedColor="hsla(215, 57%, 91%, 1)"
                pieChartLabelNameDictionary={{
                    savings: 'Saved this Month',
                    reducedCost: 'Reduced Cost this Month',
                }}
                pieChartCenteredMetricValue={'--'}
            />
        </div>
    );
};
