import { useEffect, useState } from 'react';
import { BarGraph } from '../components/BarGraph';
import { DateDropdown } from '../components/DateDropdown';
import { DummyData } from '../components/LineChartDummyData';
import { LineGraph } from '../components/LineGraph';
import { MainDropdown } from '../components/MainDropdown';
import { PieChartContainer } from '../components/PieChartContainer';
import { RecommendationsBox } from '../components/RecommendationsBox';
import { BarGraphData } from '../models/BarGraphData';
import DateEnum from '../models/DateEnum';
import RecommendationsModel from '../models/PageModels/RecommendationsModel';
import PieChartSavingsModel from '../models/PieChartModels/PieChartSavingsModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import calculatePercentageSaved from '../utils/CalculatePercentageSaved';
import { isNotNullNorUndefined } from '../utils/NullOrUndefined';
import { AnalyticsService, BillingService, PieChartService, RecommendationsService } from '../utils/apiServices';

import Bill from '../models/DatabaseModels/Bill';

const barGraphData = new BarGraphData(
    {
        originalCost: 150,
        reducedCost: 80,
    },
    // Databases category
    {
        originalCost: 100,
        reducedCost: 60,
    },
    // Storage category
    {
        originalCost: 90,
        reducedCost: 11,
    },
    // Cleanup category
    {
        originalCost: 40,
        reducedCost: 30,
    }
);

// const pieChartData = [
//     new PieChartDatum('savings', 'Savings', 10951704, 'hsla(196, 100%, 39%, 1)'),
//     new PieChartDatum('reducedCost', 'Reduced Cost', 7301137, 'hsla(215, 57%, 91%, 1)'),
// ];

// const pieChartData2 = [
//     new PieChartDatum('savings', 'Savings', 55000, 'hsla(196, 100%, 39%, 1)'),
//     new PieChartDatum('reducedCost', 'Reduced Cost', 45000, 'hsla(215, 57%, 91%, 1)'),
// ];

/**
 *
 * Dashboard Component. Shown first.
 */
export const Dashboard = () => {
    const [billingDataResponse, setBillingDataResponse] = useState<Bill[] | null>(null);
    const maxRecListItemsShown = 4;
    const [recommendationsList, setRecommendationsList] = useState<string[]>([]);

    useEffect(() => {
        const handleApiRequests = async () => {
            //            const newRequestObject = new TableDataRequest(DateEnum['Last Month'], 1, 10);

            const response = await BillingService.GetBillingDetails();

            console.log('Response:');
            console.log(response);
        };
        handleApiRequests();
    }, []);

    //useEffect(() => {
    //  const recommendations: string[] = [];
    //   for (let i = 0; i < maxRecListItemsShown; i++) {
    //       const target = billingDataResponse?.data[i];
    //      if (isNotNullNorUndefined(target)) {
    //           recommendations.push(target['notification']);
    //        }
    //     }
    //      // Set the recommendations list state with the populated array
    //       setRecommendationsList(recommendations);
    //    }, [billingDataResponse]);

    const mainDropdownItems = ['Categories', 'Overview'];
    const [selectedItem, setSelectedItem] = useState<string>(mainDropdownItems[0]);
    const [selectedDate, setSelectedDate] = useState<DateEnum>(DateEnum['Last Month']);

    // PieChart Lifetime Savings Data

    const [lifetimeSavingsData, setLifetimeSavingsData] = useState<PieChartSavingsModel | null>(null);
    const [currentMonthsSavingsData, setCurrentMonthsSavingsData] = useState<PieChartSavingsModel | null>(null);

    useEffect(() => {
        const handleLifetimeSavingsApiRequest = async () => {
            // Get Lifetime Savings Data
            const response = await PieChartService.GetLifetimeSavings();

            const newClassFromData = new PieChartSavingsModel(response.data);
            setLifetimeSavingsData(newClassFromData);
        };
        const handleCurrentMonthsSavingsApiRequest = async () => {
            // Get Currnts Month's Savings Data

            const response = await PieChartService.GetCurrentMonthsSavings();
            const newClassFromData = new PieChartSavingsModel(response.data);
            setCurrentMonthsSavingsData(newClassFromData);
        };
        handleLifetimeSavingsApiRequest();
        handleCurrentMonthsSavingsApiRequest();
    }, []);

    const handleTestSignupEndpoint = () => {
        AnalyticsService.SignUp({ serviceConnectionId: 'AD342DASFD' });
    };

    return (
        <div className="page-container">
            <button onClick={handleTestSignupEndpoint}>Test Signup Endpoint</button>

            <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
                {/* Section label and main dashboard */}
                <b>
                    <h3>Main Dashboard</h3>
                </b>
                <div className="dropdown-row">
                    <MainDropdown
                        setSelectedItem={setSelectedItem}
                        selectedItem={selectedItem}
                        dropdownItems={mainDropdownItems}
                    />
                    {/* Date dashboard */}
                    <DateDropdown
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        dateRange={DummyData.dateString}
                    />
                </div>
            </div>

            {selectedItem === 'Categories' ? (
                <BarGraph barGraphData={barGraphData} />
            ) : (
                <LineGraph lineGraphData={DummyData} />
            )}
            <div id="pie-charts" className="flex mt-[25px] w-[95%] mx-auto my-0">
                <div className="pt-[25px] border-t-[#90949A] border-t border-r-[#90949A] border-r border-solid w-[95%] min-w-[350px]">
                    <PieChartContainer<PieChartSavingsModel>
                        pieChartTitle={'Lifetime Savings'}
                        pieChartData={lifetimeSavingsData}
                        pieChartSize={{ top: 10, right: 0, bottom: 10, left: 0 }}
                        pieChartOrientation={'horizontal'}
                        pieChartContainerHasLegend={true}
                        pieChartSavingsColor={'hsla(196, 100%, 39%, 1)'}
                        pieChartReducedColor={'hsla(215, 57%, 91%, 1)'}
                        pieChartLabelNameDictionary={{
                            savings: 'Lifetime Savings',
                            reducedCost: 'Reduced Lifetime Cost',
                        }}
                        pieChartCenteredMetricValue={calculatePercentageSaved(lifetimeSavingsData)}
                    />
                </div>
                <div className="pt-[25px] border-t-[#90949A] border-t border-r-[#90949A] border-r border-solid w-[95%] min-w-[350px]">
                    <PieChartContainer<PieChartSavingsModel>
                        pieChartTitle={"Current Month's Savings"}
                        pieChartData={currentMonthsSavingsData}
                        pieChartSize={{ top: 10, right: 0, bottom: 10, left: 0 }}
                        pieChartOrientation={'horizontal'}
                        pieChartContainerHasLegend={true}
                        pieChartSavingsColor={'hsla(196, 100%, 39%, 1)'}
                        pieChartReducedColor={'hsla(215, 57%, 91%, 1)'}
                        pieChartLabelNameDictionary={{
                            savings: 'Saved This Month',
                            reducedCost: "This Month's Reduced Cost",
                        }}
                        pieChartCenteredMetricValue={calculatePercentageSaved(currentMonthsSavingsData)}
                    />
                </div>
                <div className="border-t-[#90949A] border-t border-solid pt-[25px] w-[95%] min-w-[350px]">
                    <RecommendationsBox recommendationsList={recommendationsList} />
                </div>
            </div>
        </div>
    );
};
