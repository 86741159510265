import { LicensesService } from '../../../utils/apiServices';
import { LabelNameDictionaryType } from '../_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<RecommendedLicencesModel> = {
    asset: 'Asset',
    coresNeeded: 'Cores Needed',
    coresActivated: 'Cores Activated',
};

class RecommendedLicencesModel {
    asset: string;
    coresNeeded: number;
    coresActivated: number;
    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = LicensesService.GetRecommendedLicenses;

    constructor(obj: RecommendedLicencesModel) {
        this.asset = obj.asset;
        this.coresNeeded = obj.coresNeeded;
        this.coresActivated = obj.coresActivated;
    }
}

export default RecommendedLicencesModel;
