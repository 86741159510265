import { PieLayer, ResponsivePie } from '@nivo/pie';
import { LabelNameDictionaryType } from '../models/PageModels/_Interface/IPageModelsInterface';
import { PieChartDatum } from '../models/PieChartDatum';
import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import PieChartSavingsModel from '../models/PieChartModels/PieChartSavingsModel';
import { EMPTY_VALUE_PLACEHOLDER_4_SPACES } from '../utils/EmptyValuePlaceholderUtil';
import { isNullOrUndefined } from '../utils/NullOrUndefined';
import './PieChart.css';

interface PieChartProps<T extends PieChartSavingsModel | PieChartAssetModel> {
    pieChartData: T | null;
    pieChartLabelNameDictionary: Partial<LabelNameDictionaryType<T>>;
    PieCenteredMetric: PieLayer<PieChartDatum>;
    pieToolTipFormatting: string;
    pieChartSize?: {
        top: number;
        right: number;
        bottom: number;
        left: number;
    };
    pieChartSavingsColor: string;
    pieChartReducedColor: string;
}

export const PieChart = <T extends PieChartSavingsModel | PieChartAssetModel>({
    pieChartData,
    pieChartLabelNameDictionary,
    PieCenteredMetric,
    pieToolTipFormatting,
    pieChartSize,
    pieChartReducedColor,
    pieChartSavingsColor,
}: PieChartProps<T>) => {
    console.log('Pie Chart Data:');
    console.log(pieChartData);

    const generateDataArrayInput = (pieChartData: PieChartSavingsModel | PieChartAssetModel | null) => {
        if (isNullOrUndefined(pieChartData)) {
            return [
                new PieChartDatum('firstItem', EMPTY_VALUE_PLACEHOLDER_4_SPACES, 3333, 'hsla(196, 100%, 39%, 1)'),
                new PieChartDatum('secondItem', EMPTY_VALUE_PLACEHOLDER_4_SPACES, 4444, 'hsla(215, 57%, 91%, 1)'),
            ];
        }

        const labelsDictionary = pieChartLabelNameDictionary;

        return Object.entries(labelsDictionary).map(([key, label]) => {
            const value = pieChartData[key as keyof typeof pieChartData];
            const color = key === 'savings' ? pieChartSavingsColor : pieChartReducedColor;
            return new PieChartDatum(key, label as string, value, color);
        });
    };

    return (
        <ResponsivePie
            data={generateDataArrayInput(pieChartData)}
            colors={{ datum: 'data.color' }}
            margin={pieChartSize}
            innerRadius={0.6}
            padAngle={0.7}
            activeOuterRadiusOffset={8}
            enableArcLabels={false}
            enableArcLinkLabels={false}
            tooltip={(point) => {
                return (
                    <div className="toolTip">
                        <label>{point.datum.label}</label>
                        <h3>
                            {pieToolTipFormatting}
                            {point.datum.value.toLocaleString('en-US')}
                        </h3>
                    </div>
                );
            }}
            layers={['arcs', PieCenteredMetric]}
        />
    );
};
