import { ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { BarGraphData } from '../models/BarGraphData';

interface BarGraphProps {
    barGraphData: BarGraphData;
}

export const BarGraph = ({ barGraphData }: BarGraphProps) => {
    // console.log("Bar Graph Data:");
    // console.log(barGraphData);

    const ThemeObj = {
        grid: {
            line: {
                stroke: '#DBE6F5',
                strokeWidth: 1,
                strokeDasharray: '3 4',
            },
            y: {
                tick: {
                    // Set display to 'none' for every nth tick to remove them
                    // eslint-disable-next-line no-empty-pattern
                    display: ({}, index: number) => (index % 2 !== 0 ? 'none' : 'block'),
                },
            },
        },
        lineHeight: 22,
        fontFamily: 'Rubik',
        fontSize: 14,
        textColor: '#a2a3a5',
        fontWeight: 400,
        legends: {
            text: {
                fontSize: '12',
                fill: 'rgba(17, 38, 60, 0.7)',
            },
        },
    };

    const data = [
        {
            type: 'Compute',
            'Original Cost': barGraphData.compute.originalCost,
            'Reduced Cost': barGraphData.compute.reducedCost,
        },
        {
            type: 'Databases',
            'Original Cost': barGraphData.databases.originalCost,
            'Reduced Cost': barGraphData.databases.reducedCost,
        },
        {
            type: 'Storage',
            'Original Cost': barGraphData.storage.originalCost,
            'Reduced Cost': barGraphData.storage.reducedCost,
        },
        {
            type: 'Cleanup',
            'Original Cost': barGraphData.cleanup.originalCost,
            'Reduced Cost': barGraphData.cleanup.reducedCost,
        },
    ];
    const colors = {
        'Original Cost': 'hsl(215, 57%, 91%)',
        'Reduced Cost': 'hsl(196, 100%, 39%)',
    };
    const getColor = (
        bar: ComputedDatum<{
            type: string;
            'Original Cost': number;
            'Reduced Cost': number;
        }>
    ) => colors[bar.id as keyof typeof colors];
    // const getColor = (bar) => colors[bar.id];
    return (
        <div className="graph-container">
            <ResponsiveBar
                minValue={'auto'}
                maxValue={'auto'}
                data={data}
                keys={['Original Cost', 'Reduced Cost']}
                indexBy="type"
                colorBy="id"
                colors={getColor}
                margin={{ top: 20, right: 100, bottom: 100, left: 100 }}
                padding={0.4}
                innerPadding={30}
                groupMode="grouped"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 0,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisLeft={{
                    tickValues: 4,
                    tickSize: 0,
                    tickPadding: 15,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40,
                    format: (v) => `${v}k`,
                }}
                enableLabel={false}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-left',
                        direction: 'row',
                        justify: false,
                        translateX: -50,
                        translateY: 80,
                        itemsSpacing: 50,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 1,
                        symbolSize: 20,
                        symbolShape: 'circle',
                    },
                ]}
                role="application"
                theme={ThemeObj}
                motionConfig={'wobbly'}
                tooltip={(input) => {
                    return (
                        <div className="toolTip">
                            <label>Full Payment</label>
                            <b>
                                <h3>${input.value},000</h3>
                            </b>
                        </div>
                    );
                }}
            />
        </div>
    );
};
