import React from 'react';
import { Container } from 'reactstrap';
import { NavBar } from './NavBar';
import './Layout.css';
import { SearchBar } from './SearchBar';

interface LayoutProps {
    children: React.ReactElement;
}

const Layout = ({ children }: LayoutProps) => {
    return (
        <div className="layout-container">
            <NavBar />
            <section className="search-main-group">
                <SearchBar />

                <Container className="main-container" tag="main">
                    {children ?? 'HI '}
                </Container>
            </section>
        </div>
    );
};

export default Layout;
