class PieChartAssetModel {
    // asset name
    // number of assets of that type (vm or db or other)
    // number of assets of that type that meet the criteria (licensed or reserved)
    // what we need to show is the number of assets that meet the criteria vs the ones that don't meet the criteria

    // number of met criteria vs number of unmet criteria
    assetType: string; // Put as label
    meetsCriteria: number;
    doesNotMeetCriteria: number;

    constructor(obj: PieChartAssetModel) {
        this.assetType = obj.assetType;
        this.meetsCriteria = obj.meetsCriteria;
        this.doesNotMeetCriteria = obj.doesNotMeetCriteria;
    }
}

export default PieChartAssetModel;
