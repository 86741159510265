import axios from 'axios';
import CleanupModel from '../models/PageModels/CleanupModel';
import ComputeAppServicesModel from '../models/PageModels/ComputeModels/ComputeAppServicesModel';
import ComputeContainersModel from '../models/PageModels/ComputeModels/ComputeContainersModel';
import ComputeVirtualMachinesModel from '../models/PageModels/ComputeModels/ComputeVirtualMachinesModel';
import DatabasesModel from '../models/PageModels/DatabasesModel';
import LicensesModel from '../models/PageModels/LicensesModels/LicensesModel';
import PurchasableLicneseModel from '../models/PageModels/LicensesModels/PurchasableLicensesModel';
import RecommendedLicencesModel from '../models/PageModels/LicensesModels/RecommendedLicencesModel';
import RecommendationsModel from '../models/PageModels/RecommendationsModel';
import ReservationsModel from '../models/PageModels/ReservationsModel';
import StorageModel from '../models/PageModels/StorageModel';
import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import PieChartSavingsModel from '../models/PieChartModels/PieChartSavingsModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import Bill from '../models/DatabaseModels/Bill';
import { SignUpData } from '../models/RequestModels/SignUpData';

//import T from "../models/DatabaseModels/Bill"

//console.log(T);
let root = process.env.REACT_APP_WEB_API_URL ?? 'https://localhost:7173/';

root += 'api';

// new (obj: IgnoreLabelNameDictionary<ComputeVirtualMachinesModel>) => ComputeVirtualMachinesModel{}

// // Create new object once received
// const CNOOR = <T extends IPageModelsInterface<T>>(
//     obj: { new (obj: IgnoreLabelNameDictionary<T>): T },

//     func: () => Promise<AxiosResponse<TableDataResponse<T>>>
// ): (() => Promise<TableDataResponse<T>>) => {
//     return () => {
//         return new Promise((resolve, reject) => {
//             func()
//                 .then((response) => {
//                     const newClassObjects = response.data.data.map((responseObj) => {
//                         return new obj(responseObj);
//                     });

//                     const updated = { ...response.data };
//                     updated.data = newClassObjects;

//                     resolve(updated);
//                 })
//                 .catch((err) => {
//                     reject(err);
//                 });
//         });
//     };
// };

export const TestService = {
    GetSquarehookVMLicense: () => {
        return axios.get<string>(root + '/test/GetSquarehookVMLicense');
    },
};

export const WeatherForecastService = {
    GetWeatherForecast: () => axios.get(root + '/weatherforecast/GetWeatherForecast '),
};

export const ComputeService = {
    GetVirtualMachines: (request: TableDataRequest) =>
        axios.post<TableDataResponse<ComputeVirtualMachinesModel>>(root + '/compute/GetVirtualMachines', request),
    GetContainers: (request: TableDataRequest) =>
        axios.put<TableDataResponse<ComputeContainersModel>>(root + '/compute/GetContainers', request),
    GetAppServices: (request: TableDataRequest) =>
        axios.put<TableDataResponse<ComputeAppServicesModel>>(root + '/compute/GetAppServices', request),
};

export const LicensesService = {
    GetLicenses: (request: TableDataRequest) =>
        axios.put<TableDataResponse<LicensesModel>>(root + '/licenses/GetLicenses', request),
    GetPurchasableLicenses: (request: TableDataRequest) =>
        axios.put<TableDataResponse<PurchasableLicneseModel>>(root + '/licenses/GetPurchasableLicenses', request),
    GetRecommendedLicenses: (request: TableDataRequest) =>
        axios.put<TableDataResponse<RecommendedLicencesModel>>(root + '/licenses/GetRecommendedLicenses', request),
};

export const DatabasesService = {
    GetDatabases: (request: TableDataRequest) =>
        axios.post<TableDataResponse<DatabasesModel>>(root + '/databases/GetDatabases', request),
};

export const ReservationsService = {
    GetReservations: (request: TableDataRequest) =>
        axios.put<TableDataResponse<ReservationsModel>>(root + '/reservations/GetReservations', request),
    GetReservedVsUnreserved: () => axios.get<PieChartAssetModel>(root + '/reservations/GetReservedVsUnreserved'),
};

export const StorageService = {
    GetStorage: (request: TableDataRequest) =>
        axios.put<TableDataResponse<StorageModel>>(root + '/storage/GetStorage', request),
};

export const CleanupService = {
    GetCleanup: (request: TableDataRequest) =>
        axios.post<TableDataResponse<CleanupModel>>(root + '/cleanup/GetCleanup', request),
};
export const BillingService = {
    GetBillingDetails: () => axios.get<Bill[]>(root + '/billing/GetBills'),
};

export const RecommendationsService = {
    GetRecommendations: (request: TableDataRequest) =>
        axios.put<TableDataResponse<RecommendationsModel>>(root + '/recommendations/GetRecommendations', request),
};
export const PieChartService = {
    GetLifetimeSavings: () => axios.get<PieChartSavingsModel>(root + '/piechart/GetLifetimeSavings'),
    GetCurrentMonthsSavings: () => axios.get<PieChartSavingsModel>(root + '/piechart/GetCurrentMonthsSavings'),
};

export const AnalyticsService = {
    SignUp: (signUpData: SignUpData) => axios.post<PieChartSavingsModel>(root + '/Analytics/SignUp', signUpData),
};
