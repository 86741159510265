import { useEffect, useMemo, useState } from 'react';
import { DateDropdown } from '../components/DateDropdown';
import { DummyData } from '../components/LineChartDummyData';
import { LineGraph } from '../components/LineGraph';
import { MainDropdown } from '../components/MainDropdown';
import { MonthlySavings } from '../components/MonthlySavings';
import { SelectableRowTable } from '../components/SelectableRowTable';
import { ReactComponent as DownArrow } from '../images/dropdownArrowDark.svg';
import { ReactComponent as UpArrow } from '../images/expandArrow.svg';
import DateEnum from '../models/DateEnum';
import ComputeAppServicesModel from '../models/PageModels/ComputeModels/ComputeAppServicesModel';
import ComputeContainersModel from '../models/PageModels/ComputeModels/ComputeContainersModel';
import ComputeVirtualMachinesModel from '../models/PageModels/ComputeModels/ComputeVirtualMachinesModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import { isNotNullNorUndefinedNorEmptyString } from '../utils/NullOrUndefined';
import { ComputeService } from '../utils/apiServices';

const dummyData = [
    {
        asset: 'Asset 1',
        usage: '90%',
        originalCost: '1000.50',
        reducedCost: '800.25',
        saved: '200.25',
        class: 'Class A',
        cores: 4,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 1',
        usage: '90%',
        originalCost: '1000.50',
        reducedCost: '800.25',
        saved: '200.25',
        class: 'Class A',
        cores: 4,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 2',
        usage: '80%',
        originalCost: '750.75',
        reducedCost: '600.60',
        saved: '150.15',
        class: 'Class B',
        cores: 2,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 3',
        usage: '75%',
        originalCost: '1200.25',
        reducedCost: '950.20',
        saved: '250.05',
        class: 'Class C',
        cores: 8,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 4',
        usage: '60%',
        originalCost: '600.60',
        reducedCost: '480.48',
        saved: '120.12',
        class: 'Class A',
        cores: 4,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 5',
        usage: '95%',
        originalCost: '1500.75',
        reducedCost: '1350.68',
        saved: '150.07',
        class: 'Class B',
        cores: 2,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 6',
        usage: '70%',
        originalCost: '800.40',
        reducedCost: '720.36',
        saved: '80.04',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 7',
        usage: '85%',
        originalCost: '950.25',
        reducedCost: '807.68',
        saved: '142.57',
        class: 'Class A',
        cores: 4,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 8',
        usage: '65%',
        originalCost: '1200.60',
        reducedCost: '1056.53',
        saved: '144.07',
        class: 'Class B',
        cores: 2,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 9',
        usage: '88%',
        originalCost: '850.90',
        reducedCost: '748.79',
        saved: '102.11',
        class: 'Class C',
        cores: 8,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 10',
        usage: '78%',
        originalCost: '700.35',
        reducedCost: '561.84',
        saved: '138.51',
        class: 'Class A',
        cores: 4,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 11',
        usage: '92%',
        originalCost: '1100.55',
        reducedCost: '1003.10',
        saved: '97.45',
        class: 'Class B',
        cores: 2,
        scheduling: 'Scheduled',
        licensed: 'Yes',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 12',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
    {
        asset: 'Asset 13',
        usage: '68%',
        originalCost: '950.80',
        reducedCost: '857.72',
        saved: '93.08',
        class: 'Class C',
        cores: 8,
        scheduling: 'On-Demand',
        licensed: 'No',
    },
];

export default dummyData;

export const Compute = () => {
    const mainDropdownItemsGraph = useMemo(() => ['Overview', 'Virtual Machines'], []);
    const mainDropdownItemsTable = ['Virtual Machines', 'App Services', 'Containers'] as const;

    //--------------State variables-------------
    const [pageSize, setPageSize] = useState<number>(4);
    const [updatedMainDropdownItemsGraph, setUpdatedMainDropdownItemsGraph] =
        useState<typeof mainDropdownItemsGraph>(mainDropdownItemsGraph);

    type PossibleTableDataResponseTypes =
        | TableDataResponse<ComputeVirtualMachinesModel>
        | TableDataResponse<ComputeContainersModel>
        | TableDataResponse<ComputeAppServicesModel>;

    const [tableDataResponse, setTableDataResponse] = useState<PossibleTableDataResponseTypes | null>(null);

    const [selectedLineGraphCategory, setSelectedLineGraphCategory] = useState<(typeof mainDropdownItemsGraph)[number]>(
        mainDropdownItemsGraph[0]
    );
    const [selectedLineGraphDateRange, setSelectedLineGraphDateRange] = useState<DateEnum>(DateEnum['Month to Date']);

    //dropdown menus for table
    const [selectedTableCategory, setSelectedTableCategory] = useState<(typeof mainDropdownItemsTable)[number]>(
        mainDropdownItemsTable[0]
    );

    const [selectedTableDateRange, setSelectedTableDateRange] = useState<DateEnum>(DateEnum['Month to Date']);

    // state of table, open or closed
    const [toggleTable, setToggleTable] = useState<boolean>(false);
    const [classNameGraph, setClassNameGraph] = useState('open-graph');
    const [classNameTable, setClassNameTable] = useState('closed-table');

    const [currentPage, setCurrentPage] = useState<number>(1); // State for current page
    const [totalPages, setTotalPages] = useState<number>(1); // State for total pages

    // table data
    type ComputeItemTypes = ComputeVirtualMachinesModel | ComputeContainersModel | ComputeAppServicesModel;
    const [selectedTableData, setSelectedTableData] = useState<ComputeItemTypes | null>(null);

    useEffect(() => {
        if (toggleTable) {
            setClassNameGraph('closed-graph');
            setClassNameTable('open-table');
            setPageSize(15);
            setCurrentPage(1);
        } else {
            setClassNameGraph('open-graph');
            setClassNameTable('closed-table');
            setPageSize(4);
            setCurrentPage(1);
        }
    }, [toggleTable]);

    useEffect(() => {
        setTotalPages(Math.ceil(dummyData.length / pageSize));
    }, [pageSize]);

    useEffect(() => {
        const handleApiRequests = async () => {
            console.log(selectedTableCategory);
            if (selectedTableCategory === 'Virtual Machines') {
                // const newRequestObject = new TableDataRequest(selectedTableDateRange, 1, 10);

                // const response = await ComputeService.GetVirtualMachines(newRequestObject);

                // const classObjects = response.data.data.map((modelData) => new ComputeVirtualMachinesModel(modelData));

                // setTableDataResponse({ ...response.data, data: classObjects });
                const newTableDataResponse: PossibleTableDataResponseTypes = new TableDataResponse<
                    ComputeVirtualMachinesModel | ComputeContainersModel | ComputeAppServicesModel
                >({
                    data: dummyData,
                    dateString: '',
                    currentPageNumber: 2,
                    totalPages: dummyData.length,
                    totalItems: dummyData.length,
                    categoryGroups: [],
                    category: "",
                }) as PossibleTableDataResponseTypes;

                setTableDataResponse(newTableDataResponse);
                setCurrentPage(newTableDataResponse?.currentPageNumber || 1);
                setTotalPages(Math.ceil(newTableDataResponse?.totalPages / pageSize) || 1);
            } else if (selectedTableCategory === 'App Services') {
                const newRequestObject = new TableDataRequest(selectedTableDateRange, 1, 10);

                const response = await ComputeService.GetAppServices(newRequestObject);

                const classObjects = response.data.data.map((modelData) => new ComputeAppServicesModel(modelData));

                setTableDataResponse({ ...response.data, data: classObjects });
            } else if (selectedTableCategory === 'Containers') {
                const newRequestObject = new TableDataRequest(selectedTableDateRange, 1, 10);

                const response = await ComputeService.GetContainers(newRequestObject);

                const classObjects = response.data.data.map((modelData) => new ComputeContainersModel(modelData));

                setTableDataResponse({ ...response.data, data: classObjects });
            }
        };
        handleApiRequests();
    }, [pageSize, selectedTableCategory, selectedTableDateRange]);

    useEffect(() => {
        if (selectedTableData && isNotNullNorUndefinedNorEmptyString(selectedTableData.asset)) {
            // Make a copy of the array
            const newMainDropdownItemsGraph = [...mainDropdownItemsGraph];
            // add asset to the array
            newMainDropdownItemsGraph.push(selectedTableData.asset);
            // Update the state with the modified array
            setUpdatedMainDropdownItemsGraph(newMainDropdownItemsGraph);
            // update the state of the selected line graph category
            setSelectedLineGraphCategory(selectedTableData.asset);
        }
    }, [mainDropdownItemsGraph, selectedTableData]);

    const getLabelNameDictionary = () => {
        switch (selectedTableCategory) {
            case 'Virtual Machines': {
                return ComputeVirtualMachinesModel.labelNameDictionary;
            }
            case 'App Services': {
                return ComputeAppServicesModel.labelNameDictionary;
            }
            case 'Containers': {
                return ComputeContainersModel.labelNameDictionary;
            }
        }
    };

    return (
        <div className="page-container">
            <div className={classNameGraph}>
                <div className="h-[100%] w-9/12 max-h-[800px]">
                    <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
                        {/* Section label and main dashboard */}
                        <b>
                            <h3>Compute</h3>
                        </b>
                        <div className="dropdown-row">
                            {Array.from(updatedMainDropdownItemsGraph).length === 1 ? (
                                <h3 className="dropdown">{Array.from(mainDropdownItemsGraph)}</h3>
                            ) : (
                                <MainDropdown
                                    setSelectedItem={setSelectedLineGraphCategory}
                                    selectedItem={selectedLineGraphCategory}
                                    dropdownItems={Array.from(updatedMainDropdownItemsGraph)}
                                />
                            )}
                            {/* Date dashboard */}
                            <DateDropdown
                                setSelectedDate={setSelectedLineGraphDateRange}
                                selectedDate={selectedLineGraphDateRange}
                                dateRange={DummyData.dateString}
                            />
                        </div>
                    </div>
                    <LineGraph lineGraphData={DummyData} />
                </div>
                <MonthlySavings />
            </div>

            <div className={classNameTable}>
                <div className="w-[100%] overflow-y-hidden">
                    <div className="dropdown-row">
                        {/* Section label and main dashboard */}
                        <div className="flex w-full flex-row">
                            {Array.from(mainDropdownItemsTable).length === 1 ? (
                                <h4 className="dropdown">{mainDropdownItemsTable[0]}</h4>
                            ) : (
                                <MainDropdown
                                    setSelectedItem={setSelectedTableCategory}
                                    selectedItem={selectedTableCategory}
                                    dropdownItems={Array.from(mainDropdownItemsTable)}
                                />
                            )}
                        </div>
                        <div>
                            <button
                                className="justify-center flex flex-row items-center text-[#005b99] w-full"
                                onClick={() => setToggleTable(!toggleTable)}>
                                <b>
                                    <h4 className="w-[150px]">{toggleTable ? 'Collapse' : 'Expand'} Table</h4>
                                </b>
                                {toggleTable ? <DownArrow /> : <UpArrow className="expand-arrow" />}
                            </button>
                        </div>

                        {/* Date dashboard */}
                        <DateDropdown
                            setSelectedDate={setSelectedTableDateRange}
                            selectedDate={selectedTableDateRange}
                            dateRange={DummyData.dateString}
                        />
                    </div>
                    <SelectableRowTable<ComputeItemTypes>
                        columns={getLabelNameDictionary()}
                        data={tableDataResponse?.data}
                        selectedTableData={selectedTableData}
                        setSelectedTableData={setSelectedTableData}
                        toggleTable={toggleTable}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                        totalItems={tableDataResponse?.totalItems}
                        pageSize={pageSize}
                    />
                </div>
            </div>
        </div>
    );
};
