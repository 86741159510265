import { useEffect, useState } from 'react';
import { DualPieCharts } from '../components/DualPieCharts';
import { MainDropdown } from '../components/MainDropdown';
import { SelectableRowTable } from '../components/SelectableRowTable';
import { ReactComponent as DownArrow } from '../images/dropdownArrowDark.svg';
import { ReactComponent as UpArrow } from '../images/expandArrow.svg';
import DateEnum from '../models/DateEnum';
import ReservationsModel from '../models/PageModels/ReservationsModel';
import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import calculatePercentageAsset from '../utils/CalculatePercentageAsset';
import { ReservationsService } from '../utils/apiServices';

export const Reservations = () => {
    const [tableDataResponse, setTableDataResponse] = useState<TableDataResponse<ReservationsModel> | null>(null);

    const [reservationsData, setReservationsData] = useState<PieChartAssetModel | null>(null);

    useEffect(() => {
        const handleReservationsApiRequest = async () => {
            // Get Reservations Data
            const response = await ReservationsService.GetReservedVsUnreserved();

            const newClassFromData = new PieChartAssetModel(response.data);
            setReservationsData(newClassFromData);
        };
        // const handleCurrentMonthsSavingsApiRequest = async () => {
        // Get Currnts Month's Savings Data

        // const response = await PieChartService.GetCurrentMonthsSavings();
        // const newClassFromData = new PieChartSavingsModel(response.data);
        // setCurrentMonthsSavingsData(newClassFromData);
        // };
        handleReservationsApiRequest();
        // handleCurrentMonthsSavingsApiRequest();
    }, []);

    const mainDropdownItemsTable = ['Reservations'] as const;
    //dropdown menus for table
    const [selectedItemTable, setSelectedItemTable] = useState<(typeof mainDropdownItemsTable)[number]>(
        mainDropdownItemsTable[0]
    );

    const [toggleTable, setToggleTable] = useState<boolean>(false);
    const [classNameGraph, setClassNameGraph] = useState('open-graph');
    const [classNameTable, setClassNameTable] = useState('closed-table');

    useEffect(() => {
        if (toggleTable) {
            setClassNameGraph('closed-graph');
            setClassNameTable('open-table');
        } else {
            setClassNameGraph('open-graph');
            setClassNameTable('closed-table');
        }
    }, [toggleTable]);

    // table data
    const [selectedTableData, setSelectedTableData] = useState<ReservationsModel | null>(null);

    useEffect(() => {
        const handleApiRequests = async () => {
            // The backend will ignore the 'Last Month' option in the TableDataRequest for now potentially as the reservations doesn't have a date filter
            const newRequestObject = new TableDataRequest(DateEnum['Last Month'], 1, 10);

            const response = await ReservationsService.GetReservations(newRequestObject);

            const classObjects = response.data.data.map((modelData) => new ReservationsModel(modelData));

            setTableDataResponse({ ...response.data, data: classObjects });
        };
        handleApiRequests();
    }, []);

    return (
        <div className="page-container">
            <div className={classNameGraph}>
                <div className="h-[100%] w-full max-h-[800px]">
                    <DualPieCharts
                        firstPieChartData={reservationsData}
                        secondPieChartData={null}
                        header="Reservations"
                        pieChartTitleLeft="Virtual Machines Reserved"
                        pieChartTitleRight="Other Reserved"
                        firstPieChartLabelNameDictionary={{
                            meetsCriteria: "VM's Reserved",
                            doesNotMeetCriteria: "VM's Not Reserved",
                        }}
                        secondPieChartLabelNameDictionary={{
                            meetsCriteria: 'Other Types Reserved',
                            doesNotMeetCriteria: 'Other Types Not Reserved',
                        }}
                        firstPieChartCenteredMetricValue={calculatePercentageAsset(reservationsData)}
                        secondPieChartCenteredMetricValue={'--'}
                    />
                </div>
            </div>
            <div className={classNameTable}>
                <div className="w-full">
                    <div className="flex w-[56%] flex-row justify-between mb-[20px]">
                        {Array.from(mainDropdownItemsTable).length === 1 ? (
                            <h4 className="dropdown">{mainDropdownItemsTable[0]}</h4>
                        ) : (
                            <MainDropdown
                                setSelectedItem={setSelectedItemTable}
                                selectedItem={selectedItemTable}
                                dropdownItems={Array.from(mainDropdownItemsTable)}
                            />
                        )}
                        <div>
                            <button
                                className="justify-center flex flex-row items-center text-[#005b99]"
                                onClick={() => setToggleTable(!toggleTable)}>
                                <b>
                                    <h4 className="w-[150px]">{toggleTable ? 'Collapse' : 'Expand'} Table</h4>
                                </b>
                                {toggleTable ? <DownArrow /> : <UpArrow className="expand-arrow" />}
                            </button>
                        </div>
                    </div>
                    <SelectableRowTable<ReservationsModel>
                        columns={ReservationsModel.labelNameDictionary}
                        data={tableDataResponse?.data}
                        selectedTableData={selectedTableData}
                        setSelectedTableData={setSelectedTableData}
                        toggleTable={toggleTable}
                    />
                </div>
            </div>
        </div>
    );
};
