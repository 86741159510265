import React, { useEffect, useState } from 'react';
import { Pagination, PaginationItem, PaginationLink, Table } from 'reactstrap';
import { LabelNameDictionaryType } from '../models/PageModels/_Interface/IPageModelsInterface';
import { isNotNullNorUndefined, isNullOrUndefined } from '../utils/NullOrUndefined';

interface GenericTableProps<T> {
    columns: LabelNameDictionaryType<T> | undefined;
    data: T[] | undefined;
    selectedTableData?: T | null;
    setSelectedTableData?: React.Dispatch<React.SetStateAction<T | null>>;
}

// Utility function to check if the 'proposedCost' property exists and is a string
const hasProposedCost = (item: unknown): item is { proposedCost: string } => {
    return typeof item === 'object' && item !== null && 'proposedCost' in item && typeof item.proposedCost === 'string';
};

export const SelectableButtonTable = <T,>({
    columns,
    data,
    selectedTableData,
    setSelectedTableData,
}: GenericTableProps<T>) => {
    const handleButtonClick = (rowData: T, index: number) => {
        if (setSelectedTableData) {
            setSelectedTableData(rowData);
            setSelectedRowIndex(index);
        }
    };

    const pageSize = 15;
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

    const pageCount = isNullOrUndefined(data) ? 1 : Math.ceil(data.length / pageSize);
    const [currentPage, setCurrentPage] = useState<number>(1); // State for current page
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const startIndex = (currentPage - 1) * pageSize;
    const visibleRows: T[] = isNullOrUndefined(data) ? [] : data.slice(startIndex, startIndex + pageSize);

    useEffect(() => {
        setCurrentPage(1);
    }, [data]);

    return (
        <div className="sticky top-0 z-[1] overflow-auto h-full">
            <Table className="mb-[25px]">
                <thead>
                    <tr>
                        {isNotNullNorUndefined(columns) ? (
                            Object.entries(columns).map(([key, label]) => (
                                <th className="sticky top-0 z-[1] font-semibold text-base" key={key}>
                                    {label + ''}
                                </th>
                            ))
                        ) : (
                            <th className="sticky top-0 z-[1] font-semibold text-base">No Data</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {visibleRows.map((row, index) => (
                        <tr key={index}>
                            {isNotNullNorUndefined(columns) ? (
                                Object.keys(columns).map((key) => (
                                    <td key={key}>{row[key as keyof typeof row] + ''}</td>
                                ))
                            ) : (
                                <td>No Data</td>
                            )}

                            {/* Conditional rendering for button */}
                            {handleButtonClick && (
                                <td>
                                    <button
                                        className={selectedRowIndex === index ? 'small-btn active-btn' : 'small-btn'}
                                        onClick={() => handleButtonClick(row, index)}>
                                        {hasProposedCost(row) && row.proposedCost.length > 0
                                            ? 'Take Action'
                                            : 'Details'}
                                    </button>
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
                <div className={'mb-[50px]'}>
                    <Pagination className="border-[none]">
                        {Array.from({ length: pageCount }).map((_, index) => (
                            <PaginationItem key={index} active={index + 1 === currentPage}>
                                <PaginationLink onClick={() => handlePageChange(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}
                    </Pagination>
                </div>
            </Table>
        </div>
    );
};
