import { useEffect, useState } from 'react';
import { DateDropdown } from '../components/DateDropdown';
import { DummyData } from '../components/LineChartDummyData';
import { LineGraph } from '../components/LineGraph';
import { MainDropdown } from '../components/MainDropdown';
import { MonthlySavings } from '../components/MonthlySavings';
import { SelectableRowTable } from '../components/SelectableRowTable';
import { ReactComponent as DownArrow } from '../images/dropdownArrowDark.svg';
import { ReactComponent as UpArrow } from '../images/expandArrow.svg';
import DateEnum from '../models/DateEnum';
import StorageModel from '../models/PageModels/StorageModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import { isNotNullNorUndefinedNorEmptyString } from '../utils/NullOrUndefined';
import { StorageService } from '../utils/apiServices';

const mainDropdownItemsGraph = ['Overview'];
const mainDropdownItemsTable = ['Storage'] as const;
export const Storage = () => {
    //--------------State variables-------------
    const [tableDataResponse, setTableDataResponse] = useState<TableDataResponse<StorageModel> | null>(null);

    const [updatedMainDropdownItemsGraph, setUpdatedMainDropdownItemsGraph] =
        useState<typeof mainDropdownItemsGraph>(mainDropdownItemsGraph);

    const [toggleTable, setToggleTable] = useState<boolean>(false);

    const [classNameGraph, setClassNameGraph] = useState('open-graph');
    const [classNameTable, setClassNameTable] = useState('closed-table');

    useEffect(() => {
        if (toggleTable) {
            setClassNameGraph('closed-graph');
            setClassNameTable('open-table');
        } else {
            setClassNameGraph('open-graph');
            setClassNameTable('closed-table');
        }
    }, [toggleTable]);

    // dropdown menus for line chart
    const [selectedLineGraphCategory, setSelectedLineGraphCategory] = useState<(typeof mainDropdownItemsGraph)[number]>(
        mainDropdownItemsGraph[0]
    );
    const [selectedLineGraphDateRange, setSelectedLineGraphDateRange] = useState<DateEnum>(DateEnum['Month to Date']);

    //dropdown menus for table
    const [selectedTableCategory, setSelectedTableCategory] = useState<(typeof mainDropdownItemsTable)[number]>(
        mainDropdownItemsTable[0]
    );
    const [selectedTableDateRange, setSelectedTableDateRange] = useState<DateEnum>(DateEnum['Month to Date']);

    // table data
    const [selectedTableData, setSelectedTableData] = useState<StorageModel | null>(null);

    // line chart data
    // const [lineChartData, setLineChartData] = useState<ComputeLineGraphData | null>(null);

    useEffect(() => {
        const handleApiRequests = async () => {
            const newRequestObject = new TableDataRequest(selectedTableDateRange, 1, 10);

            const response = await StorageService.GetStorage(newRequestObject);

            const classObjects = response.data.data.map((modelData) => new StorageModel(modelData));

            setTableDataResponse({ ...response.data, data: classObjects });
        };
        handleApiRequests();
    }, [selectedTableCategory, selectedTableDateRange]);

    useEffect(() => {
        if (selectedTableData && isNotNullNorUndefinedNorEmptyString(selectedTableData.asset)) {
            // Make a copy of the array
            const newMainDropdownItemsGraph = [...mainDropdownItemsGraph];
            // add asset to the array
            newMainDropdownItemsGraph.push(selectedTableData.asset);
            // Update the state with the modified array
            setUpdatedMainDropdownItemsGraph(newMainDropdownItemsGraph);
            // update the state of the selected line graph category
            setSelectedLineGraphCategory(selectedTableData.asset);
        }
    }, [selectedTableData]);

    //--------------Handling state--------------

    return (
        <div className="page-container">
            <div className={classNameGraph}>
                <div className="h-[100%] w-9/12 max-h-[800px]">
                    <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
                        {/* Section label and main dashboard */}
                        <b>
                            <h3>Storage</h3>
                        </b>
                        <div className="dropdown-row">
                            {Array.from(updatedMainDropdownItemsGraph).length === 1 ? (
                                <h4 className="dropdown">{mainDropdownItemsGraph}</h4>
                            ) : (
                                <MainDropdown
                                    setSelectedItem={setSelectedLineGraphCategory}
                                    selectedItem={selectedLineGraphCategory}
                                    dropdownItems={Array.from(updatedMainDropdownItemsGraph)}
                                />
                            )}
                            {/* Date dashboard */}
                            <DateDropdown
                                setSelectedDate={setSelectedLineGraphDateRange}
                                selectedDate={selectedLineGraphDateRange}
                                dateRange={DummyData.dateString}
                            />
                        </div>
                    </div>

                    <LineGraph lineGraphData={DummyData} />
                </div>
                <MonthlySavings />
            </div>
            <div className={classNameTable}>
                <div className="w-[100%]">
                    <div className="dropdown-row">
                        {/* Section label and main dashboard */}
                        <div className="flex w-full flex-row">
                            {Array.from(mainDropdownItemsTable).length === 1 ? (
                                <h4 className="dropdown">{mainDropdownItemsTable[0]}</h4>
                            ) : (
                                <MainDropdown
                                    setSelectedItem={setSelectedTableCategory}
                                    selectedItem={selectedTableCategory}
                                    dropdownItems={Array.from(mainDropdownItemsTable)}
                                />
                            )}
                        </div>
                        <div>
                            <button
                                className="justify-center flex flex-row items-center text-[#005b99] w-full"
                                onClick={() => setToggleTable(!toggleTable)}>
                                <b>
                                    <h4 className="w-[150px]">{toggleTable ? 'Collapse' : 'Expand'} Table</h4>
                                </b>
                                {toggleTable ? <DownArrow /> : <UpArrow className="expand-arrow" />}
                            </button>
                        </div>
                        {/* Date dashboard */}
                        <DateDropdown
                            setSelectedDate={setSelectedTableDateRange}
                            selectedDate={selectedTableDateRange}
                            dateRange={DummyData.dateString}
                        />
                    </div>
                    <SelectableRowTable<StorageModel>
                        columns={StorageModel.labelNameDictionary}
                        data={tableDataResponse?.data}
                        selectedTableData={selectedTableData}
                        setSelectedTableData={setSelectedTableData}
                        toggleTable={toggleTable}
                    />
                </div>
            </div>
        </div>
    );
};
