import { useEffect, useState } from 'react';

// Msal imports
import { AccountInfo, InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';

// Sample app imports
import { loginApiRequest } from '../utils/AuthConfig';
import { callMsGraph } from '../utils/MsGraphApiCall';
import { ErrorComponent } from './ProfileAuthComponents/ErrorComponent';
import { Loading } from './ProfileAuthComponents/Loading';
import { GraphData, ProfileData } from './ProfileData';

const ProfileContent = () => {
    const { instance, inProgress } = useMsal();
    const [graphData, setGraphData] = useState<null | GraphData>(null);

    useEffect(() => {
        if (!graphData && inProgress === InteractionStatus.None) {
            callMsGraph()
                .then((response) => setGraphData(response))
                .catch((e) => {
                    if (e instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            ...loginApiRequest,
                            account: instance.getActiveAccount() as AccountInfo,
                        });
                    }
                });
        }
    }, [inProgress, graphData, instance]);

    return <div>{graphData ? <ProfileData graphData={graphData} /> : null}</div>;
};

export function Profile() {
    const authRequest = {
        ...loginApiRequest,
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}>
            <ProfileContent />
        </MsalAuthenticationTemplate>
    );
}
