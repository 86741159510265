import { PieChartService } from '../../utils/apiServices';
import { LabelNameDictionaryType } from '../PageModels/_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<PieChartSavingsModel> = {
    savings: 'Savings',
    reducedCost: 'Reduced Cost',
};

class PieChartSavingsModel {
    savings: number;
    reducedCost: number;

    static labelNameDictionary = labelNameDictionary;

    static endpointMethod = PieChartService.GetLifetimeSavings;

    constructor(obj: PieChartSavingsModel) {
        this.savings = obj.savings;
        this.reducedCost = obj.savings;
    }
}

export default PieChartSavingsModel;
