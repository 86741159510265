import { useEffect, useState } from 'react';
import { DualPieCharts } from '../components/DualPieCharts';
import { MainDropdown } from '../components/MainDropdown';
import { SelectableRowTable } from '../components/SelectableRowTable';
import { ReactComponent as DownArrow } from '../images/dropdownArrowDark.svg';
import { ReactComponent as UpArrow } from '../images/expandArrow.svg';
import DateEnum from '../models/DateEnum';
import LicensesModel from '../models/PageModels/LicensesModels/LicensesModel';
import PurchasableLicneseModel from '../models/PageModels/LicensesModels/PurchasableLicensesModel';
import RecommendedLicencesModel from '../models/PageModels/LicensesModels/RecommendedLicencesModel';
import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import { LicensesService } from '../utils/apiServices';

export const Licenses = () => {
    const mainDropdownItemsTable = ['Current Licenses', 'Recommendations', 'Purchase Licenses'] as const;
    //dropdown menus for table
    const [selectedTableCategory, setSelectedTableCategory] = useState<(typeof mainDropdownItemsTable)[number]>(
        mainDropdownItemsTable[0]
    );

    // state of table, open or closed
    const [toggleTable, setToggleTable] = useState<boolean>(false);
    const [classNameGraph, setClassNameGraph] = useState('open-graph');
    const [classNameTable, setClassNameTable] = useState('closed-table');

    useEffect(() => {
        if (toggleTable) {
            setClassNameGraph('closed-graph');
            setClassNameTable('open-table');
        } else {
            setClassNameGraph('open-graph');
            setClassNameTable('closed-table');
        }
    }, [toggleTable]);

    const [tableDataResponse, setTableDataResponse] = useState<
        | TableDataResponse<LicensesModel>
        | TableDataResponse<PurchasableLicneseModel>
        | TableDataResponse<RecommendedLicencesModel>
        | null
    >(null);
    // table data
    type LicensesItemType = LicensesModel | RecommendedLicencesModel | PurchasableLicneseModel;
    const [selectedTableData, setSelectedTableData] = useState<
        LicensesModel | RecommendedLicencesModel | PurchasableLicneseModel | null
    >(null);

    const getLabelNameDictionary = () => {
        switch (selectedTableCategory) {
            case 'Current Licenses': {
                return LicensesModel.labelNameDictionary;
            }
            case 'Purchase Licenses': {
                return PurchasableLicneseModel.labelNameDictionary;
            }
            case 'Recommendations': {
                return RecommendedLicencesModel.labelNameDictionary;
            }
        }
    };

    useEffect(() => {
        const handleApiRequests = async () => {
            if (selectedTableCategory === 'Current Licenses') {
                const newRequestObject = new TableDataRequest(DateEnum['Last Month'], 1, 10);

                const response = await LicensesService.GetLicenses(newRequestObject);

                const classObjects = response.data.data.map((modelData) => new LicensesModel(modelData));

                setTableDataResponse({ ...response.data, data: classObjects });
            } else if (selectedTableCategory === 'Recommendations') {
                const newRequestObject = new TableDataRequest(DateEnum['Last Month'], 1, 10);

                const response = await LicensesService.GetRecommendedLicenses(newRequestObject);

                const classObjects = response.data.data.map((modelData) => new RecommendedLicencesModel(modelData));

                setTableDataResponse({ ...response.data, data: classObjects });
            } else if (selectedTableCategory === 'Purchase Licenses') {
                const newRequestObject = new TableDataRequest(DateEnum['Last Month'], 1, 10);

                const response = await LicensesService.GetPurchasableLicenses(newRequestObject);

                const classObjects = response.data.data.map((modelData) => new PurchasableLicneseModel(modelData));

                setTableDataResponse({ ...response.data, data: classObjects });
            }
        };
        handleApiRequests();
    }, [selectedTableCategory]);

    return (
        <div className="page-container">
            <div className={classNameGraph}>
                <div className="h-[100%] w-full max-h-[800px]">
                    <DualPieCharts<PieChartAssetModel>
                        header="Licenses"
                        pieChartTitleLeft="Virtual Machines Licensed"
                        pieChartTitleRight="Databases Licensed"
                        firstPieChartData={null}
                        secondPieChartData={null}
                        firstPieChartLabelNameDictionary={{
                            meetsCriteria: '',
                            doesNotMeetCriteria: '',
                        }}
                        secondPieChartLabelNameDictionary={{
                            meetsCriteria: '',
                            doesNotMeetCriteria: '',
                        }}
                        firstPieChartCenteredMetricValue={'--'} // Come back and populate later from data
                        secondPieChartCenteredMetricValue={'--'} // Come back and populate later from data
                    />
                    {/* <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
                        <b>
                            <h3>Licenses</h3>
                        </b>
                        <div className="mt-[25px] w-[100%] flex flex-row min-w-[100%] h-full">
                            <div className="text-center border-[3px] border-solid border-[black] min-w-[50%]">
                                PieChart1
                            </div>
                            <div className="text-center border-[3px] border-solid border-[black] min-w-[50%]">
                                PieChart2
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className={classNameTable}>
                <div className="w-full">
                    <div className="flex w-[56%] flex-row justify-between mb-[20px]">
                        {Array.from(mainDropdownItemsTable).length === 1 ? (
                            <h4 className="dropdown">{mainDropdownItemsTable[0]}</h4>
                        ) : (
                            <MainDropdown
                                setSelectedItem={setSelectedTableCategory}
                                selectedItem={selectedTableCategory}
                                dropdownItems={Array.from(mainDropdownItemsTable)}
                            />
                        )}
                        <div>
                            <button
                                className="justify-center flex flex-row items-center text-[#005b99]"
                                onClick={() => setToggleTable(!toggleTable)}>
                                <b>
                                    <h4 className="w-[150px]">{toggleTable ? 'Collapse' : 'Expand'} Table</h4>
                                </b>
                                {toggleTable ? <DownArrow /> : <UpArrow className="expand-arrow" />}
                            </button>
                        </div>
                    </div>
                    <SelectableRowTable<LicensesItemType>
                        columns={getLabelNameDictionary()}
                        data={tableDataResponse?.data}
                        selectedTableData={selectedTableData}
                        setSelectedTableData={setSelectedTableData}
                        toggleTable={toggleTable}
                    />
                </div>
            </div>
        </div>
    );
};
