import { ReservationsService } from '../../utils/apiServices';
import { LabelNameDictionaryType } from './_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<ReservationsModel> = {
    asset: 'Asset',
    cores: 'Cores',
    type: 'Type',
    class: 'Class',
    expiration: 'Expiration',
};

class ReservationsModel {
    asset: string;
    cores: number;
    type: string;
    class: string;
    expiration: string;

    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = ReservationsService.GetReservations;

    constructor(obj: ReservationsModel) {
        this.asset = obj.asset;
        this.cores = obj.cores;
        this.type = obj.type;
        this.class = obj.class;
        this.expiration = obj.expiration;
    }
}

export default ReservationsModel;
