import { useEffect, useState } from 'react';
import { SelectableButtonTable } from '../components/SelectableButtonTable';
import DateEnum from '../models/DateEnum';
import RecommendationsModel from '../models/PageModels/RecommendationsModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import { RecommendationsService } from '../utils/apiServices';

// Was unused. Commented Out
// const labelNameDictionary = {
//     notification: 'Notification',
//     asset: 'Asset',
//     currentCost: 'Current Cost',
//     proposedCost: 'Proposed Cost',
// };

export const Recommendations = () => {
    const [tableDataResponse, setTableDataResponse] = useState<TableDataResponse<RecommendationsModel> | null>(null);
    const [selectedTableData, setSelectedTableData] = useState<RecommendationsModel | null>(null);
    console.log(selectedTableData);

    useEffect(() => {
        const handleApiRequests = async () => {
            const newRequestObject = new TableDataRequest(DateEnum['Last Month'], 1, 10);

            const response = await RecommendationsService.GetRecommendations(newRequestObject);

            const classObjects = response.data.data.map((modelData) => new RecommendationsModel(modelData));

            setTableDataResponse({ ...response.data, data: classObjects });
        };
        handleApiRequests();
    }, []);
    return (
        <div className="page-container">
            <div className="mx-[25px]">
                <div className="flex p-[0] w-[95%] mx-auto mt-0 mb-[50px]">
                    <b>
                        <h3>Recommendations</h3>
                    </b>
                </div>
                <div className="open-table">
                    <div className="w-[60%]">
                        <div className="overflow-auto h-full pb-[25px]">
                            <SelectableButtonTable<RecommendationsModel>
                                columns={RecommendationsModel.labelNameDictionary}
                                data={tableDataResponse?.data}
                                selectedTableData={selectedTableData}
                                setSelectedTableData={setSelectedTableData}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
