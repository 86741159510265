import Counter from './components/Counter';
import FetchData from './components/FetchData';
import { Profile } from './components/Profile';
import { Dashboard } from './pages/Dashboard';
import { Recommendations } from './pages/Recommendations';
import { Cleanup } from './pages/Cleanup';
import { Storage } from './pages/Storage';
import { Databases } from './pages/Databases';
import { Compute } from './pages/Compute';
import { Settings } from './pages/Settings';
import { Account } from './pages/Account';
import { Help } from './pages/Help';
import { Reservations } from './pages/Reservations';
import { Licenses } from './pages/Licenses';

const AppRoutes = [
    // Dashboard is default. Rendered in App.tsx
    {
        path: '/counter',
        element: <Counter />,
    },
    {
        path: '/fetch-data',
        element: <FetchData />,
    },
    {
        path: '/profile',
        element: <Profile />,
    },
    {
        path: '/compute',
        element: <Compute />,
    },
    {
        path: '/databases',
        element: <Databases />,
    },
    {
        path: '/storage',
        element: <Storage />,
    },
    {
        path: '/cleanup',
        element: <Cleanup />,
    },
    {
        path: '/recommendations',
        element: <Recommendations />,
    },
    {
        path: '/licenses',
        element: <Licenses />,
    },
    {
        path: '/reservations',
        element: <Reservations />,
    },
    {
        path: '/settings',
        element: <Settings />,
    },
    {
        path: '/account',
        element: <Account />,
    },
    {
        path: '/help',
        element: <Help />,
    },
];

export default AppRoutes;
