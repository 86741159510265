import { AxiosResponse } from 'axios';
import { ComputeService } from '../../../utils/apiServices';
import TableDataRequest from '../../RequestModels/TableDataRequest';
import TableDataResponse from '../../ResponseModels/TableDataResponse';
import { LabelNameDictionaryType } from '../_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<ComputeAppServicesModel> = {
    asset: 'Asset',
    usage: 'Usage',
    originalCost: 'Original Cost',
    reducedCost: 'Reduced Cost',
    saved: 'Saved',
    scheduling: 'Scheduling',
    sizing: 'Sizing',
    reservations: 'Reservations',
    reservationLeft: 'Reservations Left',
};

class ComputeAppServicesModel {
    asset: string;
    usage: string;
    originalCost: string;
    reducedCost: string;
    saved: string;
    scheduling: string;
    sizing: string;
    reservations: string;
    reservationLeft: string;

    static labelNameDictionary: LabelNameDictionaryType<ComputeAppServicesModel> = labelNameDictionary;

    static endpointMethod: (
        request: TableDataRequest
    ) => Promise<AxiosResponse<TableDataResponse<ComputeAppServicesModel>, unknown>> = ComputeService.GetAppServices;

    constructor(obj: ComputeAppServicesModel) {
        this.asset = obj.asset;
        this.usage = obj.usage;
        this.originalCost = obj.originalCost;
        this.reducedCost = obj.reducedCost;
        this.saved = obj.saved;
        this.scheduling = obj.scheduling;
        this.sizing = obj.sizing;
        this.reservations = obj.reservations;
        this.reservationLeft = obj.reservationLeft;
    }
}
export default ComputeAppServicesModel;
