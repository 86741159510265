import { DatabasesService } from '../../utils/apiServices';
import { LabelNameDictionaryType } from './_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<DatabasesModel> = {
    asset: 'Asset',
    originalCost: 'Original Cost',
    reducedCost: 'Reduced Cost',
    saved: 'Saved',
    sizing: 'Sizing',
    cleanup: 'Clean Up',
    percentageOfDatabase: '% of Database',
    licenseTypes: 'License Types',
};

class DatabasesModel {
    asset: string;
    originalCost: string;
    reducedCost: string;
    saved: string;
    sizing: string;
    cleanup: string;
    percentageOfDatabase: string;
    licenseTypes: string; // May want to make it a string array

    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = DatabasesService.GetDatabases;

    constructor(obj: DatabasesModel) {
        this.asset = obj.asset;
        this.originalCost = obj.originalCost;
        this.reducedCost = obj.reducedCost;
        this.saved = obj.saved;
        this.sizing = obj.sizing;
        this.cleanup = obj.cleanup;
        this.percentageOfDatabase = obj.percentageOfDatabase;
        this.licenseTypes = obj.licenseTypes;
    }
}
export default DatabasesModel;
