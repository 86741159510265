import { EventMessage, InteractionType } from '@azure/msal-browser';
import 'bootstrap/dist/css/bootstrap.css';

import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { EventType } from '@azure/msal-browser/dist/event/EventType';

import { msalConfig, loginApiRequest } from './utils/AuthConfig';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import './index.css';
import { MsalAuthenticationTemplate, MsalProvider } from '@azure/msal-react';
import { ErrorComponent } from './components/ProfileAuthComponents/ErrorComponent';
import { Loading } from './components/ProfileAuthComponents/Loading';

const baseElements = Array.from(document.getElementsByTagName('base'));

if (baseElements === null || baseElements === undefined || !Array.isArray(baseElements) || baseElements.length <= 0) {
    console.error("Attempting to get baseElement! Couldn't find it!");
    throw new Error("Attempting to get baseElement! Couldn't find it");
}

const href = baseElements[0].getAttribute('href');

if (href === null || href === undefined) {
    console.error("Base Element doesn't have href value. Needs one");
    throw new Error("Base Element doesn't have href value. Needs one");
}
const rootElement = document.getElementById('root');

if (rootElement === null || rootElement === undefined) {
    console.error("Attempting to find root Element but it's undefined or null!");
    throw new Error('Attempting to get root Element but is null or undefined');
}

const root = createRoot(rootElement);

export const NAME_OF_ACCESS_TOKEN_COOKIE = 'Cloud_Cost_Control_Access_Token';
export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(() => {
    console.log('Test - initalization comlplete');
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }

    console.log('accounts');
    console.log(`${JSON.stringify(accounts)}`);

    msalInstance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
        }
    });

    root.render(
        <BrowserRouter basename={href}>
            <MsalProvider instance={msalInstance}>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={loginApiRequest}
                    errorComponent={ErrorComponent}
                    loadingComponent={Loading}>
                    <App pca={msalInstance} />
                </MsalAuthenticationTemplate>
            </MsalProvider>
        </BrowserRouter>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.unregister();

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
});
