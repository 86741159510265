import { StorageService } from '../../utils/apiServices';
import { LabelNameDictionaryType } from './_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<StorageModel> = {
    asset: 'Asset',
    originalCost: 'Original Cost',
    reducedCost: 'Reduced Cost',
    saved: 'Saved',
    lifeCycle: 'Life Cycle',
};

class StorageModel {
    asset: string;
    originalCost: string;
    reducedCost: string;
    saved: string;
    lifeCycle: string;

    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = StorageService.GetStorage;

    constructor(obj: StorageModel) {
        this.asset = obj.asset;
        this.originalCost = obj.originalCost;
        this.reducedCost = obj.reducedCost;
        this.saved = obj.saved;
        this.lifeCycle = obj.lifeCycle;
    }
}

export default StorageModel;
