class TableDataResponse<T> {
    data: T[];
    dateString: string;
    currentPageNumber: number;
    totalPages: number;
    totalItems: number;
    categoryGroups: string[];
    category: string;

    constructor(obj: TableDataResponse<T>) {
        this.data = obj.data;
        this.dateString = obj.dateString;
        this.currentPageNumber = obj.currentPageNumber;
        this.totalPages = obj.totalPages;
        this.totalItems = obj.totalItems;
        this.categoryGroups = obj.categoryGroups;
        this.category = obj.category;
    }
}

export default TableDataResponse;
