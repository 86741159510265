import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import { isNullOrUndefined } from './NullOrUndefined';

const calculatePercentageAsset = (model: PieChartAssetModel | null, defaultValue = '--') => {
    if (isNullOrUndefined(model)) {
        return defaultValue;
    }

    return Math.ceil((model.meetsCriteria / (model.meetsCriteria + model.doesNotMeetCriteria)) * 100) + '';
};

export default calculatePercentageAsset;
