import { LicensesService } from '../../../utils/apiServices';
import { LabelNameDictionaryType } from '../_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<LicensesModel> = {
    asset: 'Asset',
    totalCores: 'Total Cores',
    used: 'Used',
    azureCount: 'Azure Count',
    expiration: 'Expiration',
};

class LicensesModel {
    asset: string;
    totalCores: number;
    used: string;
    azureCount: string;
    expiration: string;
    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = LicensesService.GetLicenses;

    constructor(obj: LicensesModel) {
        this.asset = obj.asset;
        this.totalCores = obj.totalCores;
        this.used = obj.used;
        this.azureCount = obj.azureCount;
        this.expiration = obj.expiration;
    }
}

export default LicensesModel;
