import { useMsal } from '@azure/msal-react';
import { Button } from 'reactstrap';
import './Dropdown.css';

export const SignOutButton = () => {
    const { instance } = useMsal();

    return (
        <div>
            <Button
                className="account-btn dropdown-item-account account-text"
                onClick={() => instance.logoutRedirect()}
                color="inherit">
                Sign Out
            </Button>
        </div>
    );
};
