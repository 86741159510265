import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AzureAdvisor } from '../images/Azure-Advisor.svg';

interface RecommendationsBoxProps {
    recommendationsList: string[];
}

export const RecommendationsBox = ({ recommendationsList }: RecommendationsBoxProps) => {
    const [showAll, setShowAll] = useState(false);

    const displayedRecs = showAll
        ? recommendationsList.slice(0, recommendationsList.length)
        : recommendationsList.slice(0, 3);

    const handleShowMoreClick = () => {
        setShowAll(true);
    };

    return (
        <div className="w-[100%] flex flex-col h-[100%] items-center m-[25px]">
            <div className="pb-[5px] flex flex-row justify-center items-center ">
                <AzureAdvisor />
                <h3 className="font-normal py-[5px] pl-[10px] border-b border-[#DBE6F5]">Recommendations</h3>
            </div>
            <div className="leading-7 flex flex-col justify-center items-center ">
                <ul>
                    {displayedRecs.map((str: string, index: number) => (
                        <li className="w-[100%] border-b border-[#DBE6F5] p-[5px] list-disc" key={index}>
                            <h4>{str}</h4>
                        </li>
                    ))}
                </ul>
                <Link className="w-full flex justify-end" to="/recommendations">
                    <button className="link" onClick={handleShowMoreClick}>
                        See More
                    </button>
                </Link>
            </div>
        </div>
    );
};
