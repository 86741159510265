import { CleanupService } from '../../utils/apiServices';
import { LabelNameDictionaryType } from './_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<CleanupModel> = {
    resourceGroupName: 'Resource Group Name',
    groupShortName: 'Group Short Name',
    resourceName: 'Resource Name',
    entryDate: 'Entry Date',
};

class CleanupModel {
    resourceGroupName: string | null;
    groupShortName: string | null;
    resourceName: string | null;
    entryDate: string | null;

    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = CleanupService.GetCleanup;

    constructor(obj: CleanupModel) {
        this.resourceGroupName = obj.resourceGroupName;
        this.groupShortName = obj.groupShortName;
        this.resourceName = obj.resourceName;
        this.entryDate = obj.entryDate;
    }
}

export default CleanupModel;
