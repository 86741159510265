import { LabelNameDictionaryType } from '../models/PageModels/_Interface/IPageModelsInterface';
import PieChartAssetModel from '../models/PieChartModels/PieChartAssetModel';
import { PieChartContainer } from './PieChartContainer';

// const vmPieChartData = [
//     new PieChartDatum('licensedVms', 'Virtual Machines Licensed', 20, 'hsla(196, 100%, 39%, 1)'),
//     new PieChartDatum('unlicensedVms', 'Virtual Machines Unlicensed', 80, 'hsla(215, 57%, 91%, 1)'),
// ];

// const dbPieChartData = [
//     new PieChartDatum('licensedDbs', 'Databases Licensed', 3, 'hsla(196, 100%, 39%, 1)'),
//     new PieChartDatum('unlicensedDbs', 'Databases Unlicensed', 2, 'hsla(215, 57%, 91%, 1)'),
// ];

const pieChartSize = { top: 10, right: 0, left: 0, bottom: 10 };

interface DualPieChartsProps<T extends PieChartAssetModel> {
    header: string;
    pieChartTitleLeft: string;
    pieChartTitleRight: string;
    firstPieChartData: PieChartAssetModel | null;
    secondPieChartData: PieChartAssetModel | null;
    firstPieChartLabelNameDictionary: Partial<LabelNameDictionaryType<T>>;
    secondPieChartLabelNameDictionary: Partial<LabelNameDictionaryType<T>>;
    firstPieChartCenteredMetricValue: string;
    secondPieChartCenteredMetricValue: string;
}
export const DualPieCharts = <T extends PieChartAssetModel>({
    header,
    pieChartTitleLeft,
    pieChartTitleRight,
    firstPieChartData,
    secondPieChartData,
    firstPieChartLabelNameDictionary,
    secondPieChartLabelNameDictionary,
    firstPieChartCenteredMetricValue,
    secondPieChartCenteredMetricValue,
}: DualPieChartsProps<T>) => {
    return (
        <div className="h-full flex flex-col justify-center flex-nowrap mb-[25px] mx-[50px]">
            <b>
                <h3>{header}</h3>
            </b>
            <div className="mt-[25px] w-[100%] flex flex-row min-w-[100%] h-full">
                <div className="text-center min-w-[50%]">
                    <PieChartContainer
                        pieChartTitle={pieChartTitleLeft}
                        pieChartData={firstPieChartData}
                        pieChartOrientation={'horizontal'}
                        pieChartContainerHasLegend={false}
                        pieChartSize={pieChartSize}
                        pieChartSavingsColor="hsla(196, 100%, 39%, 1)"
                        pieChartReducedColor="hsla(215, 57%, 91%, 1)"
                        pieChartLabelNameDictionary={firstPieChartLabelNameDictionary}
                        pieChartCenteredMetricValue={firstPieChartCenteredMetricValue}
                    />
                </div>
                <div className="text-center min-w-[50%]">
                    <PieChartContainer
                        pieChartTitle={pieChartTitleRight}
                        pieChartData={secondPieChartData}
                        pieChartOrientation={'horizontal'}
                        pieChartContainerHasLegend={false}
                        pieChartSize={pieChartSize}
                        pieChartSavingsColor="hsla(196, 100%, 39%, 1)"
                        pieChartReducedColor="hsla(215, 57%, 91%, 1)"
                        pieChartLabelNameDictionary={secondPieChartLabelNameDictionary}
                        pieChartCenteredMetricValue={secondPieChartCenteredMetricValue}
                    />
                </div>
            </div>
        </div>
    );
};
