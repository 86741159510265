import PieChartSavingsModel from '../models/PieChartModels/PieChartSavingsModel';
import { isNullOrUndefined } from './NullOrUndefined';

const calculatePercentageSaved = (model: PieChartSavingsModel | null, defaultValue = '--') => {
    if (isNullOrUndefined(model)) {
        return defaultValue;
    }

    return Math.ceil((model.savings / (model.savings + model.reducedCost)) * 100) + '';
};

export default calculatePercentageSaved;
