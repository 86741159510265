import DateEnum from '../DateEnum';

class TableDataRequest {
    dateRange: DateEnum;
    pageNumber: number;
    itemsPerPage: number;
    targetCategory: string | undefined;
    constructor(dateRange: DateEnum, pageNumber: number, itemsPerPage: number, targetCategory: string | undefined = undefined) {
        this.dateRange = dateRange;
        this.pageNumber = pageNumber;
        this.itemsPerPage = itemsPerPage;
        this.targetCategory = targetCategory;
    }
}

export default TableDataRequest;

// new tabledatarequest

// dateRange to DateEnum['Last Year']; // 5
// ]
