import { AccountDropdown } from './AccountDropdown';
import './SearchBar.css';

export const SearchBar = () => {
    // const [isAuthenticated, setIsAuthenticated] = useState(false); // State to track authentication status

    return (
        <div
            id="search-bar-container"
            className="bg-[white] sticky z-[1] top-0 min-w-full w-fit flex justify-between px-10 h-[64px] border-b-[#90949a] border-b border-solid">
            <div className="flex items-center justify-start">
                <form method="GET">
                    <div className="relative text-[#1f384c]">
                        <span className="absolute inset-y-0 right-0 flex items-center">
                            <button type="submit" className="py-[10px] px-[15px]">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 14 14"
                                    fill="none">
                                    <g opacity="0.5">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M5.91314 10.8263C8.6266 10.8263 10.8263 8.6266 10.8263 5.91314C10.8263 3.19969 8.6266 1 5.91314 1C3.19969 1 1 3.19969 1 5.91314C1 8.6266 3.19969 10.8263 5.91314 10.8263Z"
                                            stroke="#627B87"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M9.71931 9.71886L12.8074 12.807"
                                            stroke="#627B87"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </span>
                        <input
                            id="search-input"
                            type="search"
                            name="q"
                            className="search-bar-text w-[625px] h-[32px] py-[16px] px-[15px] bg-[#f6f6fB] border-1-black rounded-[5px] placeholder-[#1f384c] placeholder-opacity-30"
                            placeholder="Search"></input>
                    </div>
                </form>
            </div>
            <div id="account-info" className="search-bar-text flex items-center">
                <div id="profile-icon" className="flex items-center py-[16px] pr-[12px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <circle cx="16" cy="16" r="16" fill="#F47A44" />
                    </svg>
                </div>
                <div id="account-name" className="flex items-center justify-end py-[16px] pr-[28px]">
                    <AccountDropdown isAuthenticated={true} />
                </div>
                {/* <div className="">
                    <svg
                        id="dropdown-chevron-icon"
                        className=""
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none">
                        <g opacity="0.5">
                            <path
                                d="M10 13.0942L15.0083 8.08591L13.8308 6.90674L10 10.7401L6.17 6.90674L4.99167 8.08507L10 13.0942Z"
                                fill="#1F384C"
                            />
                        </g>
                    </svg>
                </div> */}
                <div id="notification-container" className="flex justify-end py-[16px] pl-[32px]">
                    <svg
                        id="notif-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="13"
                        height="16"
                        viewBox="0 0 13 16"
                        fill="none">
                        <path
                            d="M6.4 16C5.51684 15.9957 4.80073 15.2831 4.792 14.4H7.992C7.99369 14.6139 7.9529 14.826 7.872 15.024C7.66212 15.5056 7.23345 15.8569 6.72 15.968H6.716H6.704H6.6896H6.6824C6.58945 15.9874 6.49492 15.9981 6.4 16ZM12.8 13.6H0V12L1.6 11.2V6.8C1.55785 5.67129 1.81275 4.5513 2.3392 3.552C2.86323 2.62521 3.75896 1.9671 4.8 1.744V0H8V1.744C10.0632 2.2352 11.2 4.0304 11.2 6.8V11.2L12.8 12V13.6Z"
                            fill="#B0C3CC"
                        />
                    </svg>
                    <svg
                        id="notif-sign-icon"
                        className="mt-[-1px] ml-[-7px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="9"
                        viewBox="0 0 10 9"
                        fill="none">
                        <circle cx="5" cy="4.77783" r="3.6" fill="#EC5252" stroke="white" strokeWidth="1.2" />
                    </svg>
                </div>
            </div>
        </div>
    );
};
