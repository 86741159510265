import { ComputeService } from '../../../utils/apiServices';
import { LabelNameDictionaryType } from '../_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<ComputeVirtualMachinesModel> = {
    asset: 'Asset', // [asset, 'Asset']
    usage: 'Usage', // [usage: 'Usage']
    originalCost: 'Original Cost',
    reducedCost: 'Reduced Cost',
    saved: 'Saved',
    class: 'Class',
    cores: 'Cores',
    scheduling: 'Scheduling',
    licensed: 'Licensed',
};

class ComputeVirtualMachinesModel {
    asset: string; // Assets
    usage: string; // Usage %
    originalCost: string; // Original Cost // Need this to be string as there are decimal points in it.
    reducedCost: string; // Reduced Cost // Need this to be string as there are decimal points in it.
    saved: string; // Saved
    class: string; // Class
    cores: number; // Cores
    scheduling: string; // Scheduling
    licensed: string; // Licensed

    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = ComputeService.GetVirtualMachines;

    constructor(obj: ComputeVirtualMachinesModel) {
        this.asset = obj.asset;
        this.usage = obj.usage;
        this.originalCost = obj.originalCost;
        this.reducedCost = obj.reducedCost;
        this.saved = obj.saved;
        this.class = obj.class;
        this.cores = obj.cores;
        this.scheduling = obj.scheduling;
        this.licensed = obj.licensed;
    }
}
export default ComputeVirtualMachinesModel;
