import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import './custom.css';
import Layout from './components/Layout';
import { IPublicClientApplication } from '@azure/msal-browser';
import { CustomNavigationClient } from './utils/NavigationClient';
import { MsalProvider } from '@azure/msal-react';

import { Dashboard } from './pages/Dashboard';

// Test change

interface AppProps {
    pca: IPublicClientApplication;
}

const App = ({ pca }: AppProps) => {
    // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between pages in your app
    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={pca}>
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return <Route key={index} {...rest} element={element} />;
                    })}

                    <Route path={'/'} element={<Dashboard />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Layout>
        </MsalProvider>
    );
};

export default App;
