import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID ?? '0ae75b9b-5908-4f2a-b19a-54fcff45d9ea',
        authority: 'https://login.microsoftonline.com/organizations',
        redirectUri: process.env.REACT_APP_REDIRECT_URL ?? 'https://localhost:44427/',
        postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            },
        },
        allowNativeBroker: false, // Disables WAM Broker
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginApiRequest = {
    scopes: ['User.Read'],
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};

export const TENANT_ID = '63d34a45-7443-42c8-83d0-a8b3eddc4cf8';
