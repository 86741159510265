import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import './Dropdown.css';

interface MainDropdownProps<T> {
    dropdownItems: string[];
    setSelectedItem: React.Dispatch<React.SetStateAction<T>>;
    selectedItem: string | undefined;
}

export const MainDropdown = <T,>({ dropdownItems, setSelectedItem, selectedItem }: MainDropdownProps<T>) => {
    // State
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    // Functions

    const getClassName = (index: number, isSelected: boolean) => {
        let className = '';
        if (isSelected) {
            className += 'selected-item ';
        }

        if (index === 0) {
            className += 'top-item ';
        } else if (index === dropdownItems.length - 1) {
            className += 'bottom-item ';
        }

        className += 'dropdown-item-main';
        return className;
    };

    return (
        <Dropdown
            className="dropdown flex items-center"
            isOpen={dropdownOpen}
            toggle={() => setDropdownOpen((dropdownOpen) => !dropdownOpen)}>
            <DropdownToggle className="dropdown-toggle main">{selectedItem}</DropdownToggle>
            <DropdownMenu className="dropdown-menu-main-date">
                {dropdownItems.map((item: string, index: number) => (
                    <DropdownItem
                        key={item}
                        className={selectedItem === item ? getClassName(index, true) : getClassName(index, false)}
                        onClick={() => setSelectedItem(item as T)}>
                        <h4>{item}</h4>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
};
