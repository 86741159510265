import React from 'react';

export type GraphData = {
    displayName: string;
    jobTitle: string;
    mail: string;
    businessPhones: string[];
    officeLocation: string;
};

export const ProfileData = ({ graphData }: { graphData: GraphData }) => {
    return (
        <div className="profileData">
            <NameListItem name={graphData.displayName} />
            <JobTitleListItem jobTitle={graphData.jobTitle} />
            <MailListItem mail={graphData.mail} />
            <PhoneListItem phone={graphData.businessPhones[0]} />
            <LocationListItem location={graphData.officeLocation} />
        </div>
    );
};

const NameListItem: React.FC<{ name: string }> = ({ name }) => (
    <div>
        {/* Person Icon Here Please */}
        <h1>Name</h1>
        {name}
    </div>
);

const JobTitleListItem: React.FC<{ jobTitle: string }> = ({ jobTitle }) => (
    <div>
        {/* Add avatar profile here please */}
        <h1>Title</h1>
        {jobTitle}
    </div>
);

const MailListItem: React.FC<{ mail: string }> = ({ mail }) => (
    <div>
        {/* Please add an avatar profile here please */}
        <h1>Mail</h1>
        {mail}
    </div>
);

const PhoneListItem: React.FC<{ phone: string }> = ({ phone }) => (
    <div>
        {/* Please add a avatar profile here please */}
        <h1>Phone</h1>
        {phone}
    </div>
);

const LocationListItem: React.FC<{ location: string }> = ({ location }) => (
    <div>
        {/* Please add a avatar profile here please */}

        <h1>Location</h1>
        {location}
    </div>
);
