import { LicensesService } from '../../../utils/apiServices';
import { LabelNameDictionaryType } from '../_Interface/IPageModelsInterface';

const labelNameDictionary: LabelNameDictionaryType<PurchasableLicneseModel> = {
    asset: 'Asset',
    fourCores: '4 Cores',
    twelveCores: '12 Cores',
    twentyFourCores: '24 Cores',
};

class PurchasableLicneseModel {
    asset: string;
    fourCores: string;
    twelveCores: string;
    twentyFourCores: string;
    static labelNameDictionary = labelNameDictionary;
    static endpointMethod = LicensesService.GetPurchasableLicenses;

    constructor(obj: PurchasableLicneseModel) {
        this.asset = obj.asset;
        this.fourCores = obj.fourCores;
        this.twelveCores = obj.twelveCores;
        this.twentyFourCores = obj.twentyFourCores;
    }
}
export default PurchasableLicneseModel;
