import { useEffect, useState } from 'react';
import { DateDropdown } from '../components/DateDropdown';
import { DummyData } from '../components/LineChartDummyData';
import { LineGraph } from '../components/LineGraph';
import { MainDropdown } from '../components/MainDropdown';
import { MonthlySavings } from '../components/MonthlySavings';
import { SelectableRowTable } from '../components/SelectableRowTable';
import { ReactComponent as DownArrow } from '../images/dropdownArrowDark.svg';
import { ReactComponent as UpArrow } from '../images/expandArrow.svg';
import DateEnum from '../models/DateEnum';
import CleanupModel from '../models/PageModels/CleanupModel';
import TableDataRequest from '../models/RequestModels/TableDataRequest';
import TableDataResponse from '../models/ResponseModels/TableDataResponse';
import { CleanupService } from '../utils/apiServices';

export const Cleanup = () => {
    //--------------State variables-------------

    const [tableDataResponse, setTableDataResponse] = useState<TableDataResponse<CleanupModel> | undefined>(undefined);

    // dropdown menus for line chart
    const [selectedLineGraphCategory, setSelectedLineGraphCategory] = useState<string | undefined>(undefined);
    const [selectedLineGraphDateRange, setSelectedLineGraphDateRange] = useState<DateEnum>(DateEnum['Month to Date']);

    //dropdown menus for table
    const [selectedTableCategory, setSelectedTableCategory] = useState<string | undefined>(undefined);
    const [selectedTableDateRange, setSelectedTableDateRange] = useState<DateEnum>(DateEnum['Month to Date']);

    const [toggleTable, setToggleTable] = useState<boolean>(false);

    // table data
    const [selectedTableRowData, setSelectedTableRowData] = useState<CleanupModel | null>(null);

    // Temp Data
    const graphCategoryGroups = ['Overview'];

    useEffect(() => {
        const handleApiRequests = async () => {
            const newRequestObject = new TableDataRequest(selectedTableDateRange, 1, 10, selectedTableCategory);

            const response = await CleanupService.GetCleanup(newRequestObject);

            const classObjects = response.data.data.map((modelData) => new CleanupModel(modelData));

            setTableDataResponse({ ...response.data, data: classObjects });

            const categoryGroups = response.data.categoryGroups;

            if (Array.isArray(categoryGroups) && categoryGroups.length > 0) {
                setSelectedTableCategory(response.data.category);
            }
        };
        handleApiRequests();
    }, [selectedTableCategory, selectedTableDateRange]);

    // useEffect(() => {

    // May need to review
    // if (selectedTableData && isNotNullNorUndefinedNorEmptyString(selectedTableData.asset)) {
    // Make a copy of the array
    // const newMainDropdownItemsGraph = [...mainDropdownItemsGraph];
    // // add asset to the array
    // newMainDropdownItemsGraph.push(selectedTableData.groupShortName);
    // // Update the state with the modified array
    // setUpdatedMainDropdownItemsGraph(newMainDropdownItemsGraph);
    // // update the state of the selected line graph category
    // setSelectedLineGraphCategory(selectedTableData.groupShortName);
    // }
    // }, [selectedTableData]);

    const tableCategoryGroups = tableDataResponse?.categoryGroups ?? [];

    return (
        <div className="page-container">
            <div className={toggleTable ? 'closed-graph' : 'open-table'}>
                <div className="h-[100%] w-9/12 max-h-[800px]">
                    <div className="h-fit flex flex-col items-start justify-center flex-nowrap mb-[25px] mx-[50px]">
                        {/* Section label and main dashboard */}
                        <b>
                            <h3>Cleanup</h3>
                        </b>
                        <div className="dropdown-row">
                            {graphCategoryGroups.length === 1 ? (
                                <h4 className="dropdown">{graphCategoryGroups[0]}</h4>
                            ) : (
                                <MainDropdown
                                    setSelectedItem={setSelectedLineGraphCategory}
                                    selectedItem={selectedLineGraphCategory}
                                    dropdownItems={graphCategoryGroups}
                                />
                            )}
                            {/* Date dashboard */}
                            <DateDropdown
                                setSelectedDate={setSelectedLineGraphDateRange}
                                selectedDate={selectedLineGraphDateRange}
                                dateRange={DummyData.dateString}
                            />
                        </div>
                    </div>

                    <LineGraph lineGraphData={DummyData} />
                </div>
                <MonthlySavings />
            </div>
            <div className={toggleTable ? 'open-table' : 'closed-table'}>
                <div className="w-[100%]">
                    <div className="dropdown-row">
                        {/* Section label and main dashboard */}
                        <div className="flex w-full flex-row">
                            {Array.from(tableCategoryGroups).length === 1 ? (
                                <h4 className="dropdown">{tableCategoryGroups[0]}</h4>
                            ) : (
                                <MainDropdown
                                    setSelectedItem={setSelectedTableCategory}
                                    selectedItem={selectedTableCategory}
                                    dropdownItems={tableCategoryGroups}
                                />
                            )}
                        </div>
                        <div>
                            <button
                                className="justify-center flex flex-row items-center text-[#005b99] w-full"
                                onClick={() => setToggleTable(!toggleTable)}>
                                <b>
                                    <h4 className="w-[150px]">{toggleTable ? 'Collapse' : 'Expand'} Table</h4>
                                </b>
                                {toggleTable ? <DownArrow /> : <UpArrow className="expand-arrow" />}
                            </button>
                        </div>
                        {/* Date dashboard */}
                        <DateDropdown
                            setSelectedDate={setSelectedTableDateRange}
                            selectedDate={selectedTableDateRange}
                            dateRange={DummyData.dateString}
                        />
                    </div>
                    <SelectableRowTable<CleanupModel>
                        columns={CleanupModel.labelNameDictionary}
                        data={tableDataResponse?.data}
                        selectedTableData={selectedTableRowData}
                        setSelectedTableData={setSelectedTableRowData}
                        toggleTable={toggleTable}
                    />
                </div>
            </div>
        </div>
    );
};
