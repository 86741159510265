export class BarGraphData {
    compute: {
        originalCost: number;
        reducedCost: number;
    };
    databases: {
        originalCost: number;
        reducedCost: number;
    };
    storage: {
        originalCost: number;
        reducedCost: number;
    };
    cleanup: {
        originalCost: number;
        reducedCost: number;
    };

    constructor(
        compute: {
            originalCost: number;
            reducedCost: number;
        },
        databases: {
            originalCost: number;
            reducedCost: number;
        },
        storage: {
            originalCost: number;
            reducedCost: number;
        },
        cleanup: {
            originalCost: number;
            reducedCost: number;
        }
    ) {
        this.compute = compute;
        this.databases = databases;
        this.storage = storage;
        this.cleanup = cleanup;
    }
}
